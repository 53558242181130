import { User } from "../types/auth";
import awsConfig from "../aws-exports";

export const getUserGroups = (user: User): string[] => {
  return user?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
}

export const getUserId = (user: User) => user.username;

export const getUserEmail = (user: User) => {
  if (user && user.attributes && user.attributes.email) {
    return user.attributes.email;
  }

  return "";
};

export const getGroupEmail = (userGroup: string) => {
  const suffix = awsConfig.environment === 'app' ? 'notissia' : awsConfig.environment;
  return `${userGroup}@${suffix}.awsapps.com`;
}

//const functions = { getUserGroups };

//export default functions;
