import EmailIcon from '@mui/icons-material/MailOutlineRounded';
import { Box, Link, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { default as classnames, default as classNames } from "classnames";
import moment from "moment";
import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import { ReactComponent as NotesIcon } from "../../../../public/svgs/edit.svg";
import { ReactComponent as CrunchBaseIcon } from "../../../assets/icons/crunchbase.svg";
import { ReactComponent as FilesIcon } from "../../../assets/icons/document.svg";
import { ReactComponent as LinkedInIcon } from "../../../assets/icons/linkedin.svg";
import { ReactComponent as ScreenshotIcon } from "../../../assets/icons/screenshot.svg";
import { ReactComponent as WorldGridIcon } from "../../../assets/icons/world.svg";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { FileStructureContext } from "../../../contexts/FileStructureContext";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import useRoute from "../../../hooks/useRoute";
import { updateDashboardFunc } from "../../../lib/helper";
import theme from "../../../theme";
import { Dashboard, Workspace } from "../../../types/files";
import { NoteMetric } from "../../../types/search";
import ObjectUtils from "../../../utils/ObjectUtils";
import DashboardSelect from "../../atoms/dashboards/DashboardSelect";
import MultiSelector from "../../atoms/MultiSelector";
import ThesisScore from "../../atoms/ThesisScore";
import StageSelector from "../crunchbase-modal/StageSelector";
import DashboardMenu from "../dashboard-menus/DashboardMenu";
import DashboardStatusMenu from "../dashboard-menus/DashboardStatusMenu";

const useStyles = makeStyles((theme) => ({
    row: {
        minWidth: '100%',
        width: 'fit-content',
        height: 'fit-content',
        borderBottom: `1px solid ${theme.colors.neutral['400']}`,
        background: `#fff`,
        "&:hover": {
            background: `rgb(246, 251, 251, 0.8)`,
        },
    },
    lastItem: {
        borderBottom: 'unset',
    },
    cell: {
        minWidth: 180,
        maxWidth: 180,
        minHeight: 60,
        maxHeight: 60,
        padding: '2px 10px',
    },
    narrowCell: {
        minWidth: 150,
        maxWidth: 150,
    },
    narrowerCell: {
        minWidth: 50,
        maxWidth: 50,
    },
    customNarrowCell: {
        minWidth: 20,
        maxWidth: 20,
    },
    wideCell: {
        minWidth: 280,
        maxWidth: 280,
    },
    widerCell: {
        minWidth: 380,
        maxWidth: 380,
    },
    fullWidthCell: {
        minWidth: 0,
        maxWidth: 'unset !important',
        width: '100%',
    },
    hiddenCell: {
        display: 'none',
    },
    endCell: {
        minWidth: 4,
        maxWidth: 4,
        padding: 'unset',
    },
    label: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        wordBreak: 'break-all',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    prevShared: {
        color: `${theme.colors.neutral['600']} !important`,
    },
    plainText: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow:'ellipsis',
        fontSize: '0.85rem',
        color: '#666666',
    },
    ownerText: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow:'ellipsis',
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 500,
        color: theme.palette.primary.light,
    },
    value: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['500'],
    },
    icon: {
        width: '20px !important',
        height: '20px !important',
    },
    checkBox: {
        padding: 'unset !important',
        marginRight: '4px !important',
        "& > svg": {
            width: '20px !important',
            height: '20px !important',
        }
    },
    wave: {
        borderRadius: '4px !important',
        height: '24px !important',
    },
    customCell: {
        minWidth: 240,
        maxWidth: 240,
    },
}));

const DashboardsTableRow: React.FC<{
    dashboard: Dashboard,
    workspace?: Workspace,
    columns: string[],
    collectionKey?: string,
    timestamp?: string,
    lastItem?: boolean,
    prevShared?: boolean,
    isPublic?: boolean,
    onHover?: (anchor: (HTMLElement|null)[], dashboard: Dashboard|null) => void,
}> = ({ dashboard, workspace, columns, collectionKey, timestamp, lastItem, prevShared, isPublic, onHover }) => {
    const classes = useStyles();
    const { tags } = useContext(GroupSettingsContext);
    const { fileStructure } = useContext(FileStructureContext);
    const { mappedOverviewQueries } = useContext(DashboardsContext);
    const { redirectToDashboard } = useRoute();
    const [updating, setUpdating] = useState<boolean>(false);

    const showColumn = (column: string) => columns.join(';').includes(column);
    const sanitizeUrl = (originUrl: string) => originUrl.replace(/^https?:\/\//, '').replace(/^www\./, '');

    const getQueryAnswer = useCallback((title: string) => (
        mappedOverviewQueries.get(`${dashboard.id}:${title}`)
    ), [dashboard, mappedOverviewQueries]);

    const color = useMemo(() => `${prevShared ? theme.colors.neutral['400'] : '#7bd4d4'}`, [prevShared]);
    const queriesLoaded = useMemo(() => !!mappedOverviewQueries.size, [mappedOverviewQueries.size]);
    const description = useMemo(() => getQueryAnswer('Digest')?.[0] || '', [getQueryAnswer]);
    const location = useMemo(() => getQueryAnswer('Location') || '', [getQueryAnswer]);
    const website = useMemo(() => getQueryAnswer('Website') || '', [getQueryAnswer]);
    const crunchbase = useMemo(() => getQueryAnswer('Crunchbase Page') || '', [getQueryAnswer]);
    const linkedIn = useMemo(() => getQueryAnswer('LinkedIn') || '', [getQueryAnswer]);
    const metrics = useMemo (() => getQueryAnswer('Note Metrics') as NoteMetric, [getQueryAnswer]);

    const displayValue = useCallback((metric: keyof NoteMetric) => {
        const value = metrics?.[metric]?.value ?? null;
        const unit = metrics?.[metric]?.units;

        if (value === null)
            return '-';

        const cleanInput = String(value).replace(/[^0-9bmkt.$%-]/gi, '').toLowerCase();
        let multiplier = 1;

        if (cleanInput.includes('t'))
            multiplier = Math.pow(10, 12);
        else if (cleanInput.includes('b'))
            multiplier = Math.pow(10, 9);
        else if (cleanInput.includes('m'))
            multiplier = Math.pow(10, 6);
        else if (cleanInput.includes('k'))
            multiplier = 1000;

        const numericValue = multiplier * parseFloat(cleanInput.replace(/[^0-9.-]/g, ''));

        if (unit === '$')
            return (`${numericValue < 0 ? '-' : ''}$${Math.abs(numericValue).toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        else if (unit === '%')
            return (`${numericValue.toFixed(1)}%`);

        return (`${numericValue}`);
    }, [metrics]);

    const handleSelectStage = useCallback(async (value: string) => {
        if (dashboard.investmentStage !== value) {
            dashboard.investmentStage = value;
            await updateDashboardFunc(dashboard);
        }
    }, [dashboard]);

    const handleSelectTags = useCallback(async (selections: string[]) => {
        if (!ObjectUtils.equalObjects(dashboard.tags, selections)) {
            dashboard.tags = selections;
            setUpdating(true);
            await updateDashboardFunc(dashboard).then(() => setUpdating(false));
        }
    }, [dashboard]);

    return (<>
        <Stack direction="column" className={classNames(classes.row, lastItem && classes.lastItem)}
            alignItems="stretch" justifyContent="flex-start">
            <Stack direction="row" alignItems="center" justifyContent="flex-start" width="100%">
                <Stack direction="column" alignItems="center" justifyContent="center" minWidth={24} width="min-content">
                    {!!collectionKey ? (
                        <DashboardSelect collection={collectionKey} dashboard={dashboard} />
                    ) : (<Box width={24} />)}
                </Stack>
                <Stack direction="column" className={classnames(classes.cell, classes.customCell)}
                    alignItems="flex-start" justifyContent="center">
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" width="100%">
                        <Link onClick={() => redirectToDashboard(dashboard.id)}
                            underline="hover" target="_self" sx={{ cursor: 'pointer' }}
                            onMouseEnter={() => onHover?.([], null)}>
                            <Typography className={classes.label}> {dashboard.title} </Typography>
                        </Link>
                        {!isPublic && (
                            <DashboardStatusMenu dashboard={dashboard} readOnly horizontal
                                onHoverAnchor={(anchor) => onHover?.([null, anchor], dashboard)} />
                        )}
                    </Stack>
                </Stack>
                {showColumn('Website') && (
                    <Stack direction="column" className={classes.cell} alignItems="flex-start" justifyContent="center">
                        {queriesLoaded ? (
                            !!website ? (
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                                    <Stack alignItems="center">
                                        <WorldGridIcon className={classes.icon} fill={color} stroke={color} />
                                    </Stack>
                                    <Typography component="a"
                                        className={classNames(classes.label, prevShared && classes.prevShared)}
                                        color={`${color} !important`}
                                        href={website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ textDecoration: 'none',
                                            "&:hover": { textDecoration: 'underline' },
                                        }}> {sanitizeUrl(website)} </Typography>
                                </Stack>
                            ) : (
                                <Typography className={classNames(classes.plainText, prevShared && classes.prevShared)} color={color}>
                                    {'N/A'}
                                </Typography>
                        )) : (
                            <Box sx={{ width: '100%' }}>
                                <Skeleton animation="wave" className={classes.wave} />
                            </Box>
                        )}
                    </Stack>
                )}
                {showColumn('Fit score') && (
                    <Stack direction="column" className={classnames(classes.cell, classes.narrowCell)}
                        alignItems="flex-start" justifyContent="center">
                        <ThesisScore
                            workspaceId={workspace?.id}
                            investmentTheses={getQueryAnswer('Investment Thesis') || []}
                            disabled={prevShared}  />
                    </Stack>
                )}
                {showColumn('Stage') && (
                    <Stack direction="column" className={classes.cell} alignItems="flex-start" justifyContent="center">
                        <StageSelector
                            defaultStage={dashboard?.investmentStage ?? undefined}
                            onSelectStage={handleSelectStage}
                            readOnly={isPublic || prevShared}
                            disabled={prevShared}
                            filled />
                    </Stack>
                )}
                {showColumn('Sectors') && (<>
                    <Stack direction="column" className={classnames(classes.cell, classes.wideCell)}
                        alignItems="flex-start" justifyContent="center">
                        <MultiSelector
                            mainOptions={tags}
                            selections={dashboard.tags || []}
                            onSelection={handleSelectTags}
                            readOnly={isPublic || prevShared}
                            disabled={prevShared}
                            loading={updating}
                            limit={3}
                            cropped={272} />
                    </Stack>
                    <Stack className={classnames(classes.cell, classes.customNarrowCell)} />
                </>)}
                {showColumn('Description') && (
                    <Stack direction="column" className={classnames(classes.cell, classes.widerCell)}
                        alignItems="flex-start" justifyContent="center">
                        {queriesLoaded ? (
                            <Typography className={classNames(classes.plainText, prevShared && classes.prevShared)}>
                                {description}
                            </Typography>
                        ) : (
                            <Box sx={{ width: '100%' }}>
                                <Skeleton animation="wave" className={classes.wave} />
                                <Skeleton animation="wave" className={classes.wave} />
                            </Box>
                        )}
                    </Stack>
                )}
                {showColumn('Location') && (
                    <Stack direction="column" className={classnames(classes.cell, classes.customCell)}
                        alignItems="flex-start" justifyContent="center">
                        {queriesLoaded ? (
                            <Typography className={classNames(classes.plainText, prevShared && classes.prevShared)}>
                                {location}
                            </Typography>
                        ) : (
                            <Box sx={{ width: '100%' }}>
                                <Skeleton animation="wave" className={classes.wave} />
                                <Skeleton animation="wave" className={classes.wave} />
                            </Box>
                        )}
                    </Stack>
                )}
                {showColumn('Data from...') && (
                    <Stack direction="column" className={classes.cell} alignItems="flex-start" justifyContent="center">
                        {queriesLoaded ? (
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                                {!!website && (
                                    <Tooltip title="Website">
                                        <Link href={website} target="_blank" rel="noopener noreferrer" underline="none"
                                            sx={{ cursor: 'pointer' }}>
                                            <WorldGridIcon className={classes.icon} fill={color} stroke={color} />
                                        </Link>
                                    </Tooltip>
                                )}
                                {!!crunchbase && (
                                    <Tooltip title="Crunchbase">
                                        <Link href={crunchbase} target="_blank" rel="noopener noreferrer" underline="none"
                                            sx={{ cursor: 'pointer' }}>
                                            <CrunchBaseIcon className={classes.icon} fill={color} />
                                        </Link>
                                    </Tooltip>
                                )}
                                {!!linkedIn && (
                                    <Tooltip title="LinkedIn">
                                        <Link href={linkedIn} target="_blank" rel="noopener noreferrer" underline="none"
                                            sx={{ cursor: 'pointer' }}>
                                            <LinkedInIcon className={classes.icon} fill={color} />
                                        </Link>
                                    </Tooltip>
                                )}
                                {!!dashboard.emailBodies?.length && (
                                    <Tooltip title="Emails">
                                        <Link onClick={() => redirectToDashboard(dashboard.id, 'emails')}
                                            underline="none" target="_self" sx={{ cursor: 'pointer' }}>
                                            <EmailIcon className={classes.icon} sx={{ fill: color }} />
                                        </Link>
                                    </Tooltip>
                                )}
                                {!!fileStructure.filter(fS => fS.dashboardId === dashboard.id)?.length && (
                                    <Tooltip title="Documents">
                                        <Link onClick={() => redirectToDashboard(dashboard.id, 'files')}
                                            underline="none" target="_self" sx={{ cursor: 'pointer' }}>
                                            <FilesIcon className={classes.icon} fill={color} />
                                        </Link>
                                    </Tooltip>
                                )}
                                {!!dashboard.notes?.length && (
                                    <Tooltip title="Notes">
                                        <Link onClick={() => redirectToDashboard(dashboard.id, 'notes')}
                                            underline="none" target="_self" sx={{ cursor: 'pointer' }}>
                                            <NotesIcon className={classes.icon} fill={color} />
                                        </Link>
                                    </Tooltip>
                                )}
                                {!!dashboard.screenshots?.filter(screenshot => !!screenshot.url)?.length && (
                                    <Tooltip title="Clipboard">
                                        <Link onClick={() => redirectToDashboard(dashboard.id, 'clipboard')}
                                            underline="none" target="_self" sx={{ cursor: 'pointer' }}>
                                            <ScreenshotIcon className={classes.icon} fill={color} />
                                        </Link>
                                    </Tooltip>
                                )}
                            </Stack>
                        ) : (
                            <Box sx={{ width: '100%' }}>
                                <Skeleton animation="wave" className={classes.wave} />
                            </Box>
                        )}
                    </Stack>
                )}
                {showColumn('Deal owner') && (
                    <Stack direction="column" className={classes.cell} alignItems="flex-start" justifyContent="center">
                        {!!dashboard.userId ? (
                            <Typography className={classNames(classes.ownerText, prevShared && classes.prevShared)}>
                                {dashboard.userId}
                            </Typography>
                        ): ('-')}
                    </Stack>
                )}
                {showColumn('Fundraising') && (
                    <Stack direction="column" className={classes.cell} alignItems="flex-start" justifyContent="center">
                        {queriesLoaded ? (
                            <Typography className={classNames(classes.plainText, prevShared && classes.prevShared)}>
                                {displayValue('Fundraising')}
                            </Typography>
                        ) : (
                            <Box sx={{ width: '100%' }}>
                                <Skeleton animation="wave" className={classes.wave} />
                            </Box>
                        )}
                    </Stack>
                )}
                {showColumn('Revenue') && (
                    <Stack direction="column" className={classes.cell} alignItems="flex-start" justifyContent="center">
                        {queriesLoaded ? (
                            <Typography className={classNames(classes.plainText, prevShared && classes.prevShared)}>
                                {displayValue('Revenue')}
                            </Typography>
                        ) : (
                            <Box sx={{ width: '100%' }}>
                                <Skeleton animation="wave" className={classes.wave} />
                            </Box>
                        )}
                    </Stack>
                )}
                {showColumn('Valuation') && (
                    <Stack direction="column" className={classes.cell} alignItems="flex-start" justifyContent="center">
                        {queriesLoaded ? (
                            <Typography className={classNames(classes.plainText, prevShared && classes.prevShared)}>
                                {displayValue('Valuation')}
                            </Typography>
                        ) : (
                            <Box sx={{ width: '100%' }}>
                                <Skeleton animation="wave" className={classes.wave} />
                            </Box>
                        )}
                    </Stack>
                )}
                {showColumn('Margins') && (
                    <Stack direction="column" className={classes.cell} alignItems="flex-start" justifyContent="center">
                        {queriesLoaded ? (
                            <Typography className={classNames(classes.plainText, prevShared && classes.prevShared)}>
                                {displayValue('Margin')}
                            </Typography>
                        ) : (
                            <Box sx={{ width: '100%' }}>
                                <Skeleton animation="wave" className={classes.wave} />
                            </Box>
                        )}
                    </Stack>
                )}
                <Stack className={classnames(classes.cell, classes.fullWidthCell)} onMouseEnter={() => onHover?.([], null)} />
                <Stack direction="column" className={classnames(classes.cell, classes.narrowCell)}
                    alignItems="flex-start" justifyContent="center">
                    <Typography className={classNames(classes.plainText, prevShared && classes.prevShared)} fontSize="0.8rem !important">
                        {moment(timestamp ?? dashboard.createdAt).fromNow()}
                    </Typography>
                </Stack>
                <Stack direction="column" className={classnames(classes.cell, classes.narrowerCell)}
                    alignItems="flex-start" justifyContent="center">
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                        {/* {(!isPublic && forSharing) && (
                            <DashboardAddToWorkspaceButton dashboard={dashboard} workspaceId={workspace?.id} />
                        )} */}
                        {/* {(!isPublic && forSharing) && (
                            <DashboardShare dashboard={dashboard} />
                        )} */}
                        {(!isPublic) && (
                            <DashboardMenu dashboard={dashboard} />
                        )}
                    </Stack>
                </Stack>
                <Stack className={classnames(classes.cell, classes.endCell)} />
            </Stack>
        </Stack>
    </>);
}

export default memo(DashboardsTableRow);
