import MailIcon from '@mui/icons-material/MailOutline';
import { Badge, Fab, } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { InboxContext } from '../../contexts/InboxContext';
import UnassignedContentsModal from "../modals/dashboard-unassigned-contents/UnassignedContentsModal";

const useStyles = makeStyles((theme) => ({
    badge: {
        '& .MuiBadge-badge': {
            position: 'absolute',
            top: 8,
            right: 8,
            width: 24,
            height: 24,
            padding: '12px 6px',
            borderRadius: '50%',
            background: theme.colors.orange['400'],
            color: 'white',
            fontSize: '0.85rem',
            fontWeight: 'bold',
            zIndex: 1100,
        },
    },
    incomingContentsButton: {
        height: 60,
        width: 60,
        boxShadow: 'none',
        textTransform: 'none',
        background: theme.colors.primary['500'],
        "&:hover": {
            background: theme.colors.primary['400'],
        }
    },
    incomingContentsOpen: {
        boxShadow: theme.shadows[5],
        background: theme.colors.primary['600'],
    },
    icon: {
        height: 32,
        width: 32,
        fill: 'white',
    },
}));

const UnassignedContentsBadge: React.FC<{}> = () => {
    const classes = useStyles();
    const { contentsData } = useContext(InboxContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [count, setCount] = useState<number>(0);

    const menuOpen = Boolean(anchorEl);

    useEffect(() =>{
        setCount(contentsData.length);
    }, [contentsData.length]);

    return (<>
        <Badge className={classes.badge} badgeContent={count}>
            <Fab className={classNames(classes.incomingContentsButton,
                menuOpen && classes.incomingContentsOpen)}
                onClick={event => setAnchorEl(event.currentTarget)}>
                <MailIcon className={classes.icon} />
            </Fab>
        </Badge>
        {!!anchorEl && (
            <UnassignedContentsModal anchorEl={anchorEl} onAnchorEl={setAnchorEl} />
        )}
    </>);
};

export default UnassignedContentsBadge;
