import { Box, IconButton, Stack } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import moment from 'moment';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { DashboardQueryAnswer } from '../../../contexts/DashboardQueriesContext';
import useContentCopy from '../../../hooks/useContentCopy';
import { scrollbarStyle } from '../../../shared/dashboard';
import { AnswerQuestionResponse } from '../../../types/search';
import SkeletonLoader from '../../atoms/SkeletonLoader';
import AnswerHeader from '../dashboard-query-answer/AnswerHeader';
import InlineAnswerEditor from '../dashboard-query-answer/InlineAnswerEditor';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        padding: 'unset',
        border: 'unset',
        background: 'transparent',
        transition: 'all 0.5s ease',
    },
    answerContainer: {
        width: 'auto',
        height: 'auto',
        marginLeft: 8,
        marginRight: 8,
        paddingLeft: 8,
        paddingRight: 8,
        border: `1px transparent solid`,
        borderRadius: 12,
    },
    answerHover: {
        '&:hover': {
            border: `1px ${theme.colors.neutral['400']} solid`,
            borderRadius: 12,
        }
    },
    answerBlock: {
        width: '100%',
        height: '48px',
        paddingTop: 8,
        paddingBottom: 8,
        color: '#666666',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    icon: {
        color: theme.colors.neutral['500'],
        "& > svg": {
            width: 20,
            height: 20,
        },
    },
}));

const MaxWaitLoadingTime = 15;

const InlineTextQueryContainer: React.FC<{
    queryAnswer: DashboardQueryAnswer,
    children: ReactNode,
    displayMenu?: boolean,
    onEdit: (answer: AnswerQuestionResponse) => void
    onDelete?: () => void,
}> = ({ queryAnswer, children, displayMenu, onEdit, onDelete }) => {
    const classes = useStyles();
    const { getCopiableId } = useContentCopy();

    const [isEditingAnswer, setIsEditingAnswer] = useState<boolean>(false);
    const [editAnswer, setEditAnswer] = useState<string | null>(null);
    const [hoverAnswer, setHoverAnswer] = useState<boolean>(false);
    const [showAnswer, setShowAnswer] = useState<boolean>(false);

    const { answeredQuestion } = queryAnswer;
    const copiableId = getCopiableId(answeredQuestion?.question);

    const saveEditAnswer = useCallback((answer: string) => {
        onEdit?.({answer: answer, sources: answeredQuestion.sources, type: answeredQuestion.type});
        setIsEditingAnswer(false);
        setEditAnswer(null);
    }, [answeredQuestion.sources, answeredQuestion.type, onEdit]);

    useEffect(() => {
        if ('answer' in answeredQuestion)
            setShowAnswer(((typeof answeredQuestion?.answer === 'string') || !!answeredQuestion?.type)
                || (!!answeredQuestion?.time && moment().diff(answeredQuestion.time, "seconds") > MaxWaitLoadingTime));
        else
            setShowAnswer(true);
    }, [answeredQuestion]);

    return (<>
        <Box className={classes.masonryElement}>
            <AnswerHeader
                answeredQuestion={answeredQuestion}
                displayMenu={displayMenu}
                onSetIsEditingAnswer={() => setIsEditingAnswer(true)}
                onSetEditAnswer={(answer: any) => setEditAnswer(answer as string)}
                onDelete={onDelete}
                hideCopy
                hideAiGenerated />
            {isEditingAnswer ? (
                <Box px={2}>
                    <InlineAnswerEditor
                        currentAnswer={editAnswer || ''}
                        placeHolder={'Add answer...'}
                        handleCancel={() => {
                            setIsEditingAnswer(false);
                            setEditAnswer(null);
                            setHoverAnswer(false);
                        }}
                        handleSave={saveEditAnswer} />
                </Box>
            ) : (
                <Stack direction="row" justifyContent="space-between"
                    className={classnames(classes.answerContainer, hoverAnswer && classes.answerHover)}
                    onMouseLeave={() => setHoverAnswer(false)}
                    onClick={!answeredQuestion.answer ? (e) => {
                        e.stopPropagation();
                        setIsEditingAnswer?.(true);
                        setEditAnswer(answeredQuestion.answer! as string);
                    } : undefined}
                    onDoubleClick={!!answeredQuestion.answer ? (e) => {
                        e.stopPropagation();
                        setIsEditingAnswer?.(true);
                        setEditAnswer(answeredQuestion.answer! as string);
                    } : undefined}>
                    <Box id={copiableId} className={classes.answerBlock} style={{ height: 'auto' }}
                        onMouseEnter={() => setHoverAnswer(displayMenu || false)}>
                        {showAnswer ? ( children ) : (<SkeletonLoader variant="regular" rowCount={4} cropLast />)}
                    </Box>
                    {displayMenu && hoverAnswer && (
                        <IconButton size="small"
                            className={classes.icon}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsEditingAnswer(true);
                                setEditAnswer(answeredQuestion.answer! as string);
                            }} disableRipple> <EditOutlinedIcon fontSize="small" /> </IconButton>
                    )}
                </Stack>
            )}
        </Box>
    </>);
}

export default InlineTextQueryContainer;
