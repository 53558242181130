import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconButton, Stack, TextField, TextFieldProps } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import React, { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
    answerEditContainer: {
        width: '98%',
        padding: 8,
        backgroundColor: "#fff",
        borderRadius: 10,
        border: `1px ${theme.colors.neutral['500']} solid`,
    },
    inputField: {
        "& .MuiOutlinedInput-root": {
            width: '100%',
            padding: 'unset',
            "& > input": {
                fontSize: '1rem',
                padding: 'unset',
            },
        },
    },
    saveButton: {
        padding: 'unset',
        color: theme.palette.primary.main,
        "& > svg": {
            width: 28,
            height: 28,
        },
    },
    cancelButton: {
        padding: 'unset',
        color: theme.colors.neutral['500'],
        "& > svg": {
            width: 28,
            height: 28,
        },
    },
}));

const InlineAnswerEditor: React.FC<{
    currentAnswer: string,
    placeHolder?: string,
    handleCancel: (e: any) => void,
    handleSave: (answer: string) => void,
}> = ({ placeHolder, currentAnswer, handleSave, handleCancel }) => {
    const classes = useStyles();
    const inputValue = useRef<TextFieldProps>(null);

    return (<>
        <Stack direction="row" className={classes.answerEditContainer} alignItems="center" justifyContent="space-between">
            <TextField variant="outlined"
                className={classnames('no-border', classes.inputField)}
                inputProps={{ style: { height: '100%' }, className: 'no-fade' }}
                style={{ width: '100%', height: '100%', justifyContent: 'center' }}
                defaultValue={currentAnswer}
                placeholder={placeHolder || ''}
                inputRef={inputValue}
                rows={1}
            />
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing="2px" alignItems="center" justifyContent="flex-end">
                    <IconButton className={classes.saveButton}
                        onClick={() => handleSave(inputValue.current?.value as string)}>
                        <CheckCircleIcon />
                    </IconButton>
                    <IconButton className={classes.cancelButton}
                        onClick={() => handleCancel(true)}>
                        <CancelIcon />
                    </IconButton>
                </Stack>
            </Stack>
        </Stack>
    </>);
}

export default InlineAnswerEditor;
