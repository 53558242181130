import React, { useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Stack, Typography } from "@mui/material";
import classnames from "classnames";
import InvestorsMultiAction from "../multi-action/InvestorsMultiAction";

const useStyles = makeStyles((theme) => ({
    row: {
        minWidth: '100%',
        width: 'fit-content',
        height: 'fit-content',
        borderBottom: `1px solid ${theme.colors.neutral['400']}`,
        background: `#fff`,
    },
    header: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['900'],
    },
    checkbox: {
        minWidth: 24,
        maxWidth: 24,
    },
    cell: {
        minWidth: 200,
        maxWidth: 200,
        minHeight: 60,
        maxHeight: 60,
        padding: '0 10px',
    },
    wideCell: {
        minWidth: 250,
        maxWidth: 250,
    },
    fullWidthCell: {
        minWidth: 0,
        maxWidth: 'unset !important',
        width: '100%',
    },
    endCell: {
        minWidth: 4,
        maxWidth: 4,
        padding: 'unset',
    },
    commonButton: {
        height: 36,
        borderRadius: 32,
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
    },
    icon: {
        width: 20,
        height: 20,
    }
}));

const TableHeaders = ['Name', 'Email', 'Sector', 'Stage', 'Geography', ''];

const WorkspacesTableHeader: React.FC<{ multiAction?: boolean }> = ({ multiAction }) => {
    const classes = useStyles();
    const multiActionRef = useRef<any>(null);

    return (<>
        <Stack direction="row" className={classes.row} alignItems="flex-start" justifyContent="flex-start" ref={multiActionRef}>
            <Stack className={classes.checkbox} />
            {TableHeaders.map((header, i, self) => (
                <Stack direction="column" className={classnames(classes.cell,
                    (i === 2) && classes.wideCell,
                    (i === 3) && classes.wideCell,
                    (i === 4) && classes.wideCell,
                    (i === self.length - 1) && classes.fullWidthCell)}
                    alignItems={(i === self.length - 1) ? 'flex-end' : 'flex-start'}
                    justifyContent="center" key={'investors-table-header-83-' + i}>
                    <Typography className={classes.header}>{header}</Typography>
                </Stack>
            ))}
            <Stack className={classnames(classes.cell, classes.endCell)} />
        </Stack>
        {multiAction && (<InvestorsMultiAction ref={multiActionRef} />)}
    </>);
}

export default WorkspacesTableHeader;