import { useCallback, useContext } from 'react';
import { DashboardsContext, StatusGroupList } from '../contexts/DashboardsContext';
import { GroupSettingsContext } from '../contexts/GroupSettingsContext';
import { DropResult } from 'react-beautiful-dnd';
import { updateDashboardFunc } from '../lib/helper';
import useBackwardsCompatible from './useBackwardsCompatible';
import { Dashboard } from '../types/files';

const useDashboards = () => {
  const { statuses, setStatuses } = useContext(GroupSettingsContext);
  const { dashboards, dashboardsLoaded, externalDashboards,
    setDashboards, deleteDashboard } = useContext(DashboardsContext);
    const { dashboardWorkspaces } = useBackwardsCompatible();

  const filterDashboardList = useCallback((search: string, workspaceId?: string) => {
    const searchText = search.trim().toLowerCase();
    const keywords = searchText.split(',').map(token => token.trim());
    let filtered: Dashboard[] = [];

    if (!workspaceId)
      filtered = dashboards;
    else if (externalDashboards.some(dashboard => dashboardWorkspaces(dashboard)?.includes(workspaceId)))
      filtered = externalDashboards;
    else
      filtered = dashboards.filter(dashboard => dashboardWorkspaces(dashboard)?.includes(workspaceId));

    if (keywords.some(keyword => keyword.length > 0)) {
      filtered = filtered.filter(dashboard => dashboard.title.toLowerCase().includes(searchText)
        // || dashboard.tags?.join(',').toLowerCase().includes(searchText)
        // || (dashboard.status || '<rename this status>')?.toLowerCase().includes(searchText)
      );
    }

    return filtered;
  // eslint-disable-next-line
  }, [dashboards, externalDashboards, statuses]);

  const filterDashboardGroup = useCallback((filter: string, workspaceId?: string) => {
    const dashboardStatusGroup: StatusGroupList = {};
    const statusGroup: string[] = [];
    const newStatuses: string[] = [];

    if (!filter)
      statuses.forEach(status => statusGroup.push(status));

    filterDashboardList(filter, workspaceId)?.forEach(dashboard => {
      const status = (dashboard.status || '<rename this status>').toLowerCase();

      if (!statusGroup.includes(status) && !newStatuses.includes(status))
        newStatuses.push(status);
      if (dashboardStatusGroup[status])
        dashboardStatusGroup[status].push(dashboard);
      else
        dashboardStatusGroup[status] = [dashboard];
    });

    if (!!newStatuses.length) {
      newStatuses.forEach(status => statusGroup.push(status));
      if (!filter)
        setStatuses(statusGroup);
    }

    return [statusGroup, dashboardStatusGroup] as [string[], StatusGroupList];
  }, [filterDashboardList, setStatuses, statuses]);

  const dragAndDropDashboard = useCallback((result: DropResult) => {
    const { source, destination, draggableId, type } = result;

    if (!destination)
      return;

    switch (type) {
      case 'group': (async () => {
        const sourceIdx = source.droppableId === 'dnd-dashboard-group' ? source.index : -1;
        const destIdx = destination.droppableId === 'dnd-dashboard-group' ? destination.index : -1;

        if (sourceIdx !== destIdx && destIdx >= 0) {
          const updatedStatus = [...statuses];
          const movableStatus = updatedStatus.splice(sourceIdx, 1)[0];

          updatedStatus.splice(destIdx, 0, movableStatus);
          setStatuses(updatedStatus);
        }
      })(); break;
      case 'item': (async () => {
        const sourceStatus = source.droppableId.search('dnd-group') !== -1
          ? source.droppableId.replace('dnd-group:', '') : '';
        const destStatus = destination.droppableId.search('dnd-group') !== -1
          ? destination.droppableId.replace('dnd-group:', '') : '';

        if (sourceStatus !== destStatus && !!destStatus) {
          const updatedDashboards = [...dashboards];
          const movableDashboard = updatedDashboards.find(dashboard => dashboard.id === draggableId);

          if (movableDashboard) {
            movableDashboard!.status = destStatus;
            setDashboards(updatedDashboards);
            await updateDashboardFunc({ ...movableDashboard, status: destStatus, });
          }
        }
      })(); break;
      default:
        break;
    }
  }, [dashboards, statuses, setDashboards, setStatuses]);

  return { dashboardsLoaded, filterDashboardList, filterDashboardGroup,
      dragAndDropDashboard, deleteDashboard };
};

export default useDashboards;