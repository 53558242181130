import TeamQuery from "../../molecules/dashboard-queries/TeamQuery";
import KeyPeopleQuery from "../../molecules/dashboard-queries/KeyPeopleQuery";
import AddKeyPeople from "../../atoms/AddKeyPeople";
import { useContext } from "react";
import {DashboardContext} from "../../../contexts/DashboardContext";

const PeopleColumnQueries: React.FC<{}> = () => {
    const { isPublicView } = useContext(DashboardContext);

    return (<>
        <TeamQuery />
        <KeyPeopleQuery />
        {!isPublicView && (<AddKeyPeople />)}
    </>);
}

export default PeopleColumnQueries;