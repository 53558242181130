import { useState, useMemo, useEffect, useRef, useContext } from "react";
import { Divider, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {DashboardsContext} from "../../../contexts/DashboardsContext";
import useDashboards from "../../../hooks/useDashboards";
import { Dashboard, Workspace } from "../../../types/files";
import ArrayUtils from "../../../utils/ArrayUtils";
import configuredAsyncComponentLoader, { FallbackLoading } from "../../templates/loader";
import DashboardsSearchOrCreate from "../dashboards-search-create/DashboardsSearchOrCreate";
import WorkspacesTableRow from "../workspaces-table/WorkspacesTableRow";
import { AllCompaniesSelects } from "../workspaces/AllMatches";

const DashboardCardMatchesList = configuredAsyncComponentLoader(
    () => import('../../atoms/dashboards/DashboardCardMatchesList'),
    { fallback: <FallbackLoading height={`calc(100vh - 250px)`} />}
);

const useStyles = makeStyles((theme) => ({
    mainTitle: {
        fontFamily: 'Inter',
        fontSize: '1.3rem !important',
        fontWeight: '700 !important',
        color: theme.palette.primary.main,
    },
    divider: {
        width: '100%',
        borderColor: theme.colors.cyan['100'],
        margin: '8px 4px',
    },
    selectorForm: {
        minWidth: 150,
        width: 'fit-content',
        height: 'auto',
        padding: 0,
        border: 0,
        "& > .MuiInputBase-root":{
            "& > .MuiSvgIcon-root": {
                width: 24,
                height: 24,
                fill: theme.colors.neutral['600'],
                '&.Mui-disabled': {
                    fill: 'rgba(0, 0, 0, 0.26)',
                },
            },
            "& fieldset": {
              border: 0,
            },
        },
    },
    menuItem: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 8,
    },
    menuLabel: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['600'],
    },
    menuSelected: {
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 700,
        color: theme.colors.neutral['600'],
    },
    scrollable: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
    },
}));

const ShareEachCompany: React.FC<{ workspace: Workspace }> = ({ workspace }) => {
    const classes = useStyles();
    const { mappedOverviewQueries } = useContext(DashboardsContext);
    const { dashboardsLoaded, filterDashboardList } = useDashboards();

    const [selected, setSelected] = useState<AllCompaniesSelects>(AllCompaniesSelects.TopMatches);
    const [search, setSearch] = useState<string>('');
    const [dashboardArray, setDashboardArray] = useState<Dashboard[]>([]);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const dashboardScoreMap: Map<Dashboard, number> = useMemo(() => new Map(
        filterDashboardList(search || '').map(dashboard => {
            const investmentThesis: { workspaceId: string, score: number}[]
                = mappedOverviewQueries.get(`${dashboard.id}:Investment Thesis`) || [];
            const score = investmentThesis?.find(iT => iT.workspaceId === workspace!.id)?.score ?? 0;

            return [dashboard, score];
        }))
    , [filterDashboardList, workspace, mappedOverviewQueries, search]);

    const hasPreferences = useMemo(() => [
        Boolean(workspace?.investmentThesis?.focusArea?.tags?.length),
        Boolean(workspace?.investmentThesis?.stage?.stages?.length),
        Boolean(workspace?.investmentThesis?.geography?.regions?.length),
        Boolean(workspace?.investmentThesis?.additionalDetails?.freeText?.length),
    ].some(prop => prop), [workspace?.investmentThesis]);

    const hasThesisScores = useMemo(() => (
        Array.from(dashboardScoreMap).some(entry => !!entry[1])
    ), [dashboardScoreMap]);

    const loading = useMemo(() =>
        (!dashboardsLoaded || (!!dashboardScoreMap.size && !dashboardArray.length))
    , [dashboardArray.length, dashboardScoreMap, dashboardsLoaded]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            const dashboards = Array.from(dashboardScoreMap)
                .sort((a, b) => a[0].title.localeCompare(b[0].title))
                .sort((a, b) => b[1] - a[1]).map(entry => entry[0]);

            setDashboardArray((selected === AllCompaniesSelects.MostRecent)
                ? ArrayUtils.sortByDescending([...dashboards], 'createdAt') : dashboards);
            clearTimeout(timerRef.current);
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    }, [dashboardScoreMap, selected]);

    useEffect(() => {
        setSelected((hasPreferences && hasThesisScores) ? AllCompaniesSelects.TopMatches : AllCompaniesSelects.MostRecent);
    }, [hasPreferences, hasThesisScores]);

    return (<>
        <Typography className={classes.mainTitle}>
            {'Select which companies to share with this investor:'}
        </Typography>
        <Divider className={classes.divider} />
        <WorkspacesTableRow workspace={workspace} forSharing solo />
        <Divider className={classes.divider} />
        <Stack alignItems="center" width="100%" px={3} py={1}>
            <DashboardsSearchOrCreate search mediumFit onSearch={(value) => setSearch(value)} />
        </Stack>
        <Stack width="100%" height="45vh" overflow="hidden">
            <FormControl className={classes.selectorForm} variant="outlined">
                <Select value={selected} onChange={(e) => setSelected(e.target.value as AllCompaniesSelects)}
                    renderValue={(selected: string) => (
                        <Typography className={classes.menuSelected}>
                            {selected}
                        </Typography>
                    )}>
                    <MenuItem className={classes.menuItem} value={AllCompaniesSelects.TopMatches}>
                        <Typography className={classes.menuLabel}>
                            {AllCompaniesSelects.TopMatches}
                        </Typography>
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={AllCompaniesSelects.MostRecent}>
                        <Typography className={classes.menuLabel}>
                            {AllCompaniesSelects.MostRecent}
                        </Typography>
                    </MenuItem>
                </Select>
            </FormControl>
            <Stack direction="column" className={classes.scrollable} spacing={1} alignItems="flex-start" justifyContent="stretch">
                {loading ? (<FallbackLoading />) : (
                    <DashboardCardMatchesList
                        workspace={workspace}
                        dashboards={dashboardArray}
                        collectionKey={`selected-companies:${workspace.id}`}
                        display noSharing />
                )}
            </Stack>
        </Stack>
    </>);
}

export default ShareEachCompany;