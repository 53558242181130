import { Box, Button, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import { defaultGeographies, defaultStages } from "../../../shared/dashboard";
import MultiSelector from "../../atoms/MultiSelector";

const useStyles = makeStyles((theme) => ({
    title: {
        minWidth: 70,
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
    label: {
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
    clear: {
        minWidth: 80,
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 600,
        color: theme.palette.warning.dark,
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
        "&:hover": {
            background: 'transparent',
        }
    },
    count: {
        minWidth: 100,
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    cropped: {
        maxWidth: 300,
        minHeight: 35,
        maxHeight: 70,
        overflowX: 'hidden',
        overflowY: 'auto',
    },
}));

const WorkspacesTableFilter: React.FC<{
    count: number,
    onFilter?: ({ focusArea, stage, geography } : {
        focusArea: string[],
        stage: string[],
        geography: string[],
    }) => void,
}> = ({ count, onFilter }) => {
    const classes = useStyles();
    const { tags } = useContext(GroupSettingsContext);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [selectedStages, setSelectedStages] = useState<string[]>([]);
    const [selectedGeography, setSelectedGeography] = useState<string[]>([]);

    const hasSelection = useMemo(() =>
        !![...selectedTags, ...selectedStages, ...selectedGeography].length
    , [selectedGeography, selectedStages, selectedTags]);

    const handleFilter = useCallback((selections: string[], type?: string) => {
        switch (type) {
            case 'focusArea':
                setSelectedTags(selections);
                onFilter?.({ focusArea: selections, stage: selectedStages, geography: selectedGeography });
                break;
            case 'stage':
                setSelectedStages(selections);
                onFilter?.({ focusArea: selectedTags, stage: selections, geography: selectedGeography });
                break;
            case 'geography':
                setSelectedGeography(selections);
                onFilter?.({ focusArea: selectedTags, stage: selectedStages, geography: selections });
                break;
            default:
                setSelectedTags([]);
                setSelectedStages([]);
                setSelectedGeography([]);
                onFilter?.({ focusArea: [], stage: [], geography: [] });
                break;
        }
    }, [onFilter, selectedStages, selectedGeography, selectedTags]);

    return (<>
        <Stack direction="row" alignItems="center" justifyContent="space-between" px={2} pb={1}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
                <Typography className={classes.title}>{'View by:'}</Typography>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography className={classes.label}>
                            {'sector: '}
                        </Typography>
                        <Box className={classes.cropped}>
                            <MultiSelector
                                mainOptions={tags}
                                selections={selectedTags}
                                onSelection={(selections) => handleFilter(selections as string[], 'focusArea')}
                                fullSpan
                                withSearch />
                        </Box>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography className={classes.label}>
                            {'stage: '}
                        </Typography>
                        <Box className={classes.cropped}>
                            <MultiSelector
                                mainOptions={defaultStages}
                                selections={selectedStages}
                                onSelection={(selections) => handleFilter(selections as string[], 'stage')}
                                fullSpan />
                        </Box>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography className={classes.label}>
                            {'geography: '}
                        </Typography>
                        <Box className={classes.cropped}>
                            <MultiSelector
                                mainOptions={defaultGeographies}
                                selections={selectedGeography}
                                onSelection={(selections) => handleFilter(selections as string[], 'geography')}
                                fullSpan />
                        </Box>
                    </Stack>
                    {hasSelection && (
                        <Button variant="text"
                            className={classes.clear}
                            onClick={() => handleFilter([])}>
                            {'clear all'}
                        </Button>
                    )}
                </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                {!!count && (
                    <Typography className={classes.count}>
                        {`${count} person${count > 1 ? 's' : ''}`}
                    </Typography>
                )}
            </Stack>
        </Stack>
    </>);
}

export default WorkspacesTableFilter;