import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddInvestorPerson from "../../atoms/AddInvestorPerson";
import {ReactComponent as InvestorsIcon} from "../../../assets/icons/investors.svg";

const useStyles = makeStyles((theme) => ({
    mainText: {
        fontFamily: 'Inter',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    subText: {
        fontFamily: 'Inter',
        fontSize: '1.25rem',
        fontWeight: 600,
        color: '#3ABDC6',
        textAlign: 'center',
    },
    otherText: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 400,
        color: theme.colors.neutral['600'],
        textAlign: 'center',
    },
}));

const InvestorsIconFC: React.FC<{ color: string }> = ({ color }) => (
    <InvestorsIcon width={150} height={120} stroke={color} />
);


const NoInvestorsYetView: React.FC<{}> = () => {
    const classes = useStyles();

    return (<>
        <Stack spacing={3} alignItems="center" justifyContent="center" width="100%" height="calc(100vh - 300px)">
        <InvestorsIconFC color="rgb(135, 142, 149)" />
            <Typography className={classes.mainText}>
                {'Add your network here'}
                </Typography>
            <Typography className={classes.subText}>
                {'Notissia is the first peer-to-peer,'}<br/>
                {'network-based deal flow sharing platform.'}
            </Typography>
            <Typography className={classes.otherText}>
                {'Add investors and others from your network and share curated'}<br />
                {'deal flow with them, based on your and their preferences.'}
            </Typography>
            <Box />
            <AddInvestorPerson />
        </Stack>
    </>);
}

export default NoInvestorsYetView;