import React, { useContext, useState } from "react";
import { DashboardContext } from "../../../contexts/DashboardContext";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import TeamAnswer from "../dashboard-query-answer/TeamAnswer";
import TeamQueryContainer from "../dashboard-query-container/TeamQueryContainer";

const TeamQuery: React.FC<{}> = () => {
    const { isPublicView } = useContext(DashboardContext);
    const { queryAnswer, updateQuery } = useDashboardQuery('Team');
    const [isSkipped, setSkippped] = useState<boolean>(false);

    return (<>
        <TeamQueryContainer
            queryAnswer={queryAnswer}
            displayMenu={!isPublicView}
            titleOnly={isSkipped}
            onEdit={updateQuery}>
            <TeamAnswer answeredQuestion={queryAnswer.answeredQuestion} onSkip={setSkippped} />
        </TeamQueryContainer>
    </>);
}

export default TeamQuery;
