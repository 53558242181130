import React, { cloneElement, useContext, useMemo } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import GroupIcon from '@mui/icons-material/Group';
import { List, ListItem, Stack, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import Logo from "../../assets/images/sidebar-logo-revamped.svg";
import {ReactComponent as InvestorsIcon} from "../../assets/icons/investors.svg";
import { AuthContext } from "../../contexts/AuthContext";

type SideMenu = {
    route: string[];
    tooltip: string;
    icon: JSX.Element;
    handleClick?: any;
};

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        width: 94,
        height: '100vh',
        background: theme.colors.neutral['100'],
    },
    logoContainer: {
        width: 50,
        height: 50,
    },
    logo: {
        marginTop: 10,
        height: 27,
        width: 32,
    },
    tab: {
        display: "flex",
        justifyContent: "center",
        color: theme.colors.neutral["600"],
    },
    selectedTab: {
        position: "relative",
        zIndex: 2,
        "&::after": {
            content: '" "',
            position: "absolute",
            width: "48px",
            height: "48px",
            backgroundColor: theme.colors.primary["600"],
            borderRadius: "8px",
        },
    },
}));

const InvestorsIconFC: React.FC<{ color: string }> = ({ color }) => (
    <InvestorsIcon width={32} height={27} stroke={color} />
);

const Pages: SideMenu[] = [
    { route: ['/home'], tooltip: 'Home', icon: <HomeIcon />, },
    { route: ['/dashboards'], tooltip: 'Companies', icon: <DashboardIcon />, },
    { route: ['/investors', '/workspaces'], tooltip: 'Network', icon: <InvestorsIconFC color="rgb(135, 142, 149)" />, },
    { route: ['/market'], tooltip: 'Market Insights', icon: <TrendingUpIcon />, },
    { route: ['/integrations'], tooltip: 'Integrations', icon: <DeviceHubIcon />, },
];

const Sidebar: React.FC<{ flags?: any }> = ({ flags }) => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const { logout, isNotissiaAdmin, userGroup } = useContext(AuthContext);

    // TODO: Drop whole useEffect and 'if' once flags.userAdmin is removed. The GroupIcon will be shown for everyone then
    const actions: SideMenu[] = useMemo(() => {
        let actions: SideMenu[] = [
            { route: ['/subscriptions'], tooltip: 'Manage Plans', icon: <AccountCircleIcon />, handleClick: () => history.push('/subscriptions'), },
            { route: ['/logout'], tooltip: 'Sign Out', icon: <LogoutIcon />, handleClick: logout, },
        ];

        if (isNotissiaAdmin || flags.userAdmin)
            actions.unshift({ route: [isNotissiaAdmin ? '/groups' : `/groups/${userGroup}`], tooltip: 'Manage Users', icon: <GroupIcon /> });

        return actions;
    }, [isNotissiaAdmin, userGroup, history, logout, flags.userAdmin]);

    return (
        <Stack className={classes.rootContainer} alignItems="center" pt={5} pb={2}>
            <Stack className={classes.logoContainer} alignItems="center">
                <img className={classes.logo} src={Logo} alt="Notissia" />
            </Stack>
            <Stack alignItems="center" justifyContent="space-between" pt={2} height="100%">
                <List component="nav" key="sidebar-94-top">
                    {Pages.map(({ icon, route, tooltip }, i) => {
                        const isSelected = route.includes('/')
                            ? route.includes(location.pathname)
                            : route.some(r => location.pathname.startsWith(r));

                        return (
                            <ListItem component={NavLink}
                                className={classes.tab}
                                activeClassName={classes.selectedTab}
                                style={{ marginTop: 15 }}
                                isActive={() => isSelected}
                                to={route[0]}
                                key={'sidebar-92-' + i}>
                                <Tooltip title={tooltip} placement="right"
                                    componentsProps={{ tooltip: { sx: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 'auto',
                                        height: 'fit-content',
                                        fontSize: '1rem',
                                    }}}}>
                                    <IconButton style={{ color: isSelected ? 'white' : 'inherit', zIndex: 1 }}>
                                        {cloneElement(icon, { color: isSelected ? 'white' : 'rgb(135, 142, 149)' })}
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                        );
                    })}
                </List>
                <List component="nav" key="sidebar-125-bottom">
                    {actions.map(({ icon, route, tooltip, handleClick }, i) => {
                        const isSelected = route.includes('/')
                            ? route.includes(location.pathname)
                            : route.some(r => location.pathname.startsWith(r));

                        return (
                            <ListItem component={NavLink}
                                className={classes.tab}
                                activeClassName={classes.selectedTab}
                                isActive={() => isSelected}
                                to={route[0] ?? location.pathname}
                                key={'sidebar-127-' + i}>
                                <Tooltip title={tooltip} placement="right"
                                    componentsProps={{ tooltip: { sx: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 'auto',
                                        height: 'fit-content',
                                        fontSize: '1rem',
                                    }}}}>
                                    <IconButton onClick={handleClick}
                                        style={{ color: isSelected ? 'white' : 'inherit', zIndex: 1 }} >
                                        {icon}
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                        );
                    })}
                </List>
            </Stack>
        </Stack>
    );
}

export default withLDConsumer()(Sidebar);
