import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import mailProviders from "../../../helpers/mailProviders.json";
import { createWorkspaceFunc, updateWorkspaceFunc } from "../../../lib/helper";
import { defaultGeographies, defaultStages } from "../../../shared/dashboard";
import { Workspace } from "../../../types/files";
import MultiSelector from "../../atoms/MultiSelector";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 550,
            height: 'fit-content',
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.3rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'hidden',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 0,
    },
    label: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    caption: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        color: theme.colors.neutral['500'],
    },
    inputField: {
        width: '100%',
        "& .MuiOutlinedInput-root": {
            height: 40,
            borderRadius: 8,
            padding: '0 8px !important',
            background: '#fff',
            "& > input, > textarea": {
                fontFamily: 'Lato',
                fontSize: '1.2rem',
                color: '#048290',
                padding: 'unset !important',
            },
            "& fieldset": {
              	borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              	borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              	borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    existingIcon: {
        width: 20,
        height: 20,
        color: theme.colors.error['700'],
    },
    existingCaption: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '0.9rem',
        color: theme.colors.error['700'],
    },
    commonButton: {
        minWidth: 80,
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
    },
    cancelButton: {
        width: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const CreateEditInvestorModal: React.FC<{
    isOpen: boolean,
    workspaceId?: string,
    fields?: string[],
    onClose: () => void,
}> = ({ isOpen, workspaceId, fields, onClose }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { setWorkspace } = useContext(DashboardsContext);
    const { tags, workspaces, setWorkspaces } = useContext(GroupSettingsContext);

    const [saving, setSaving] = useState<boolean>(false);
    const [saveReady, setSaveReady] = useState<boolean>(false);
    const [existing, setExisting] = useState<boolean>(false);
    const [sector, setSector] = useState<string[]>([]);
    const [stage, setStage] = useState<string[]>([]);
    const [geography, setGeography] = useState<string[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    const nameRef = useRef<TextFieldProps>(null);
    const emailRef = useRef<TextFieldProps>(null);
    const websiteRef = useRef<TextFieldProps>(null);

    const workspace = useMemo(() => workspaces.find(ws => ws.id === workspaceId), [workspaceId, workspaces]);

    const workspaceNames = useMemo(() =>
        workspaces.map(ws => ws.name.trim().toLowerCase())
    , [workspaces]);

    const commonDomainsList = useMemo(() =>
        Object.entries(mailProviders as { [key: string]: string | string [] }).find(([key, _]) => key === 'result')?.pop() ?? []
    , []);

    const getDomain = useCallback((email?: string) => {
        if (!!email && email.indexOf('@') !== -1) {
            const domain = (email.split('@').pop() || '')?.trim().toLowerCase();

            return !commonDomainsList.includes(domain) ? domain : '';
        }

        return '';
    }, [commonDomainsList]);

    const inferDomain = useCallback(() => {
        if (!!emailRef.current && !!websiteRef.current)
            websiteRef.current.value = getDomain(emailRef.current?.value as string);
    }, [getDomain]);

    const handleSave = useCallback(() => {
        const name = (nameRef.current?.value as string || workspace?.name || '').trim();
        const email = (emailRef.current?.value as string || workspace?.email || '').trim();
        const website = (websiteRef.current?.value as string || workspace?.website || '').trim();
        const investmentThesis = {
            focusArea: { tags: sector },
            stage: { stages: stage },
            geography: { regions: geography },
            additionalDetails: { freeText: null },
        };

        setSaving(true);
        if (!workspace) {
            if (!workspaceNames.includes(name.trim().toLowerCase())) {
                createWorkspaceFunc({ name, email, website, isPublic: false })
                    .then((newWorkspace?: Workspace) => {
                        setSaving(false);
                        if (!newWorkspace?.id) {
                            setExisting(true);
                        } else {
                            const updatedWorkspace = {...newWorkspace, investmentThesis};

                            updateWorkspaceFunc(updatedWorkspace).then(() => {
                                setWorkspace(updatedWorkspace);
                                setWorkspaces(prev => [...prev, updatedWorkspace]);
                                enqueueSnackbar('Investor created successfully', {
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    },
                                    autoHideDuration: 4000,
                                });
                            });
                            onClose?.();
                        }
                    });
            } else {
                setExisting(true);
                setSaving(false);
            }
        } else {
            const filteredWorkspaceNames = workspaceNames.filter(wsName => wsName !== workspace?.name?.trim().toLowerCase());

            if ((!fields || fields?.includes('investor name')) && filteredWorkspaceNames.includes(name.trim().toLowerCase())) {
                setExisting(true);
                setSaving(false);
            } else {
                updateWorkspaceFunc({...workspace, name, email, website, investmentThesis })
                    .then((updatedWorkspace?: Workspace) => {
                        setSaving(false);
                        if (!updatedWorkspace?.id) {
                            setExisting(true);
                        } else {
                            setWorkspace(updatedWorkspace);
                            setWorkspaces(prev => prev.map(ws =>
                                ws.id === workspace.id! ? {...updatedWorkspace} : ws
                            ));
                            enqueueSnackbar('Investor updated successfully', {
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                },
                                autoHideDuration: 4000,
                            });
                            onClose();
                        }
                    });
            }
        }
    // eslint-disable-next-line
    }, [fields, geography, sector, stage, workspace, workspaceNames]);

    const handleClear = useCallback(() => {
        if (nameRef.current)
            nameRef.current.value = '';
        if (emailRef.current)
            emailRef.current.value = '';
        if (websiteRef.current)
            websiteRef.current.value = '';
        setExisting(false);
    }, []);

    useEffect(() => {
        if (!!workspace && !loaded) {
            setSector(workspace.investmentThesis?.focusArea?.tags ?? []);
            setStage(workspace.investmentThesis?.stage?.stages ?? []);
            setGeography(workspace.investmentThesis?.geography?.regions ?? []);
            setLoaded(true);
        }
    // eslint-disable-next-line
    }, [workspace]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose()}>
            <DialogTitle className={classes.dialogTitle}>
                {!workspace ? 'Add a new ' : 'Edit existing '}
                {'person'}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Stack direction="column" spacing={2} alignItems="stretch" justifyContent="space-between" width="100%">
                    {(!fields || fields?.includes('investor name')) && (
                        <Stack direction="row" spacing={1} alignItems="center" width="100%">
                            <Typography className={classes.label} width="85px">{'Name'}</Typography>
                            <TextField variant="outlined"
                                className={classes.inputField}
                                placeholder={'(required)'}
                                defaultValue={workspace?.name || ''}
                                onChange={() => {setExisting(false); setSaveReady(!!nameRef.current?.value);}}
                                inputRef={nameRef}
                                disabled={saving}
                                rows={1}
                                autoFocus />
                        </Stack>
                    )}
                    {(!fields || fields?.includes('email')) && (
                        <Stack direction="row" spacing={1} alignItems="center" width="100%">
                            <Typography className={classes.label} width="85px">{'Email'}</Typography>
                            <TextField variant="outlined"
                                className={classes.inputField}
                                placeholder={'(required)'}
                                defaultValue={workspace?.email || ''}
                                onChange={() => {setSaveReady(!!emailRef.current?.value); inferDomain();}}
                                inputRef={emailRef}
                                disabled={saving}
                                rows={1} />
                        </Stack>
                    )}
                    {(!fields || fields?.includes('website')) && (
                        <Stack direction="row" spacing={1} alignItems="center" width="100%">
                            <Typography className={classes.label} width="85px">{'Website'}</Typography>
                            <TextField variant="outlined"
                                className={classes.inputField}
                                placeholder={'(optional)'}
                                defaultValue={workspace?.website || getDomain(workspace?.email)}
                                inputRef={websiteRef}
                                disabled={saving}
                                rows={1} />
                        </Stack>
                    )}
                    {(!fields || fields?.includes('sectors')) && (
                        <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="flex-start" width="100%">
                            <Typography className={classes.label} width="100%">
                                {'Sectors of interest'}
                                <Typography component="span" className={classes.caption} display="inline">
                                    {' (optional)'}
                                </Typography>
                            </Typography>
                            <MultiSelector
                                mainOptions={tags}
                                selections={sector}
                                onSelection={(selections) => {
                                    setSector(selections);
                                    setSaveReady(true);
                                }} withSearch />
                        </Stack>
                    )}
                    {(!fields || fields?.includes('stage')) && (
                        <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="flex-start" width="100%">
                            <Typography className={classes.label} width="100%">
                                {'Stage'}
                                <Typography component="span" className={classes.caption} display="inline" width="100%">
                                    {' (optional)'}
                                </Typography>
                            </Typography>
                            <MultiSelector
                                mainOptions={defaultStages}
                                selections={stage}
                                onSelection={(selections) => {
                                    setStage(selections);
                                    setSaveReady(true);
                                }} />
                        </Stack>
                    )}
                    {(!fields || fields?.includes('geography')) && (
                        <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="flex-start" width="100%">
                            <Typography className={classes.label} width="100%">
                                {'Geography'}
                                <Typography component="span" className={classes.caption} display="inline">
                                    {' (optional)'}
                                </Typography>
                            </Typography>
                            <MultiSelector
                                mainOptions={defaultGeographies}
                                selections={geography}
                                onSelection={(selections) => {
                                    setGeography(selections);
                                    setSaveReady(true);
                                }} />
                        </Stack>
                    )}
                </Stack>
                {existing && (
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" mt={4} mb={2}>
                        <ErrorOutlineIcon className={classes.existingIcon} />
                        <Typography className={classes.existingCaption}>
                            {'Investor already exists'}
                        </Typography>
                    </Stack>
                )}
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Button
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={() => onClose()}
                > {'Cancel'} </Button>
                {existing ? (
                    <Button
                        variant="outlined"
                        className={classes.commonButton}
                        onClick={handleClear}
                    > {'Clear'} </Button>
                ) : (
                    <LoadingButton
                        variant="contained"
                        className={classes.commonButton}
                        loading={saving}
                        disabled={!saveReady}
                        onClick={handleSave}
                    > {'Save'} </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    </>);
}

export default CreateEditInvestorModal;