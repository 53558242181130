import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Workspace } from "../../../types/files";
// import AddCompany from "./AddCompany";
// import FromEmailsDocsend from "./FromEmailsDocsend";
// import ForwardCompanies from "./ForwardCompanies";
// import InBulkCSV from "./InBulkCSV";
// import { ChromeExtensionLink } from "../../../shared/dashboard";
// import { ReactComponent as ChromeIcon } from "../../../assets/icons/chrome-colored.svg";

const useStyles = makeStyles((theme) => ({
    image: {
        width: 150,
        height: 120,
    },
    smallImage: {
        width: 80,
        height: 'auto',
    },
    mainText: {
        fontFamily: 'Inter',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    mainLabel: {
        fontFamily: 'Inter',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    subText: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 400,
        color: theme.colors.neutral['600'],
        textAlign: 'center',
    },
    otherText: {
        fontFamily: 'Lato',
        fontSize: '1.1rem',
        fontWeight: 'normal',
        color: theme.colors.neutral['600'],
        textAlign: 'center',
    },
    bulkIcon: {
        width: 24,
        height: 24,
    },
    button: {
        height: 45,
        marginTop: 16,
        marginBottom: 16,
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'normal',
        color: 'white',
        borderRadius: 24,
        textTransform: 'none',
    },
    icon: {
        width: 32,
        height: 32,
    },
}));

const NoDashboardsFoundView: React.FC<{
    noResult?: boolean,
    noMatch?: boolean,
    noData?: boolean,
    isPublic?: boolean,
    workspace?: Workspace,
    fullWidth?: boolean,
    onCreateModalOpen?: (open: boolean) => void,
}> = ({ noResult, noMatch, noData, isPublic, workspace, fullWidth, onCreateModalOpen }) => {
    const classes = useStyles();

    return (<>
        {noResult ? (<>
            <Stack alignItems="center" justifyContent="center" width={fullWidth ? '88vw' : '100%'} height="calc(100vh - 300px)">
                <img src="../../images/missing-dashboard.png" alt="missing-dashboard" className={classes.image} />
                {isPublic ? (<>
                    <Typography className={classes.mainText} mt={4}>{'No companies yet!'}</Typography>
                    {/* <Button variant="contained"
                        className={classes.button}
                        startIcon={<ChromeIcon className={classes.icon} />}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(ChromeExtensionLink, '_blank', 'noopener, noreferrer');
                        }} disableRipple>
                        {'Install our'}
                        <Typography fontWeight="bold" fontSize="1.1rem">&nbsp;{'Chrome Extension'}</Typography>
                    </Button>
                    <Stack alignItems="center" justifyContent="center" width="100%">
                        <Typography className={classes.otherText}>
                            {'Notissia will ingest deals from your email as you open them.'}
                        </Typography>
                    </Stack> */}
                </>) : (<>
                    <Typography className={classes.mainText} mt={4}>{'No companies found!'}</Typography>
                </>)}
            </Stack>
        </>) : (noMatch) ? (<>
            <Stack alignItems="center" justifyContent="center" width="100%" height="calc(100vh - 300px)">
                <img src="../../images/missing-dashboard.png" alt="missing-dashboard" className={classes.image} />
                <Typography className={classes.mainText} mt={4}>{'No companies yet.'}</Typography>
                <Stack spacing={1} alignItems="center" justifyContent="center" width="100%" height="fit-content" mt={3}>
                    <Typography className={classes.subText}>
                        {'Companies that are shared by'}
                        <br />
                        {`${workspace?.name} will be shown here.`}
                    </Typography>
                </Stack>
            </Stack>
        </>) : (noData) ? (<>
            <Stack alignItems="center" justifyContent="center" width="100%" height="calc(100vh - 300px)">
                <img src="../../images/missing-dashboard.png" alt="missing-dashboard" className={classes.image} />
                <Typography className={classes.mainText} mt={4}>{'No data yet!'}</Typography>
                <Stack spacing={1} alignItems="center" justifyContent="center" width="100%" height="fit-content" mt={3}>
                    <Typography className={classes.subText}>
                        <span>{'Get insight on where your deal flow is coming from'}</span><br />
                        <span>{'by simply forwarding deals from your inbox.'}</span>
                    </Typography>
                </Stack>
            </Stack>
        </>) : (<>
            <Stack alignItems="center" spacing={1} justifyContent="flex-start" width="100%" height="calc(100vh - 300px)" mt={5}>
                <Stack direction="row" spacing={3} alignItems="center">
                    <img src="../../images/missing-dashboard.png" alt="missing-dashboard" className={classes.smallImage} />
                    <Typography className={classes.mainLabel}>{'No companies yet.'}</Typography>
                </Stack>
                {/* <Typography className={classes.subText} color="#666666 !important">
                    {'There are several ways you can add new companies: '}
                </Typography>
                <Stack spacing={2} pt={2}>
                    <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" width="100%">
                        <AddCompany onModalOpen={onCreateModalOpen} />
                        <FromEmailsDocsend />
                    </Stack>
                    <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" width="100%">
                        <ForwardCompanies />
                        <InBulkCSV />
                    </Stack>
                </Stack> */}
            </Stack>
        </>)}
    </>);
}

export default NoDashboardsFoundView;