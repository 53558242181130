import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import {AuthContext} from "../../contexts/AuthContext";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import theme from "../../theme";
import { useSnackbar } from "notistack";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getGroupEmail } from "../../helpers/authUser";

const useStyles = makeStyles((theme) => ({
    mailContainer: {
        background: '#fff',
        borderRadius: 16,
        border: `1px solid ${theme.colors.neutral['400']}`,
        padding: 8,
        paddingLeft: 10,
    },
    subject: {
        fontSize: '0.85rem',
        fontFamily: 'Inter',
        fontWeight: 500,
        color: theme.colors.neutral['700'],
        padding: 2,
    },
    subtitle: {
        color: theme.colors.neutral['600'],
        fontSize: '0.9rem',
        fontFamily: 'Inter',
    },
    link: {
        fontSize: '0.9rem',
        fontFamily: 'Inter',
        fontWeight: 500,
        color: theme.colors.cyan['500'],
        cursor: 'pointer',
    },
    content: {
        fontSize: '0.75rem',
        fontFamily: 'Inter',
        color: theme.colors.neutral['500'],
    },
}));

const SampleEmailBody: React.FC<{}> = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { userGroup } = useContext(AuthContext);

    return (<>
        <Stack className={classes.mailContainer} spacing="4px" alignItems="flex-start" justifyContent="center" width="100%">
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                <Typography className={classes.subtitle}>
                    {'FWD: '}
                    <Typography component="span" className={classes.link}
                        onClick={() => window.open(`mailto: ${getGroupEmail(userGroup)}`, '_self', 'noopener, noreferrer')}
                        display="inline">
                        {getGroupEmail(userGroup)}
                    </Typography>
                </Typography>
                <CopyToClipboard text={`${getGroupEmail(userGroup)}`}
                    options={{ format: "text/plain" }}
                    onCopy={() => {
                        enqueueSnackbar(`Successfully copied to clipboard.`,
                            { anchorOrigin: { vertical: "bottom", horizontal: "right" }, });
                    }}>
                    <IconButton size="small" sx={{ color: theme.colors.cyan['500'] }}>
                        <ContentCopyIcon sx={{ width: '20px', height: '20px', }} />
                    </IconButton>
                </CopyToClipboard>
            </Stack>
            <Divider className={classes.content} sx={{ minWidth: '100%' }} />
            <Typography className={classes.subject}>
                {'>>>Company name'}
            </Typography>
            <Divider className={classes.content}
                sx={{ alignItems: 'flex-start', minWidth: '100%' }} >
                {'Original email'}
            </Divider>
            <Stack alignItems="flex-start">
                <Typography className={classes.content}>
                    {'From: ...'}
                </Typography>
                <Typography className={classes.content}>
                    {'To: ...'}
                </Typography>
                <Typography className={classes.content}>
                    {'Subject: ...'}
                </Typography>
            </Stack>
        </Stack>
    </>);
}

export default SampleEmailBody;

