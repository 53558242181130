import { Typography } from "@mui/material";
import { AnsweredQuestion } from "../../../contexts/DashboardQueriesContext";
import { NoInfoYet } from "../../../types/search";
import { Fragment, useEffect, useState } from "react";
import theme from "../../../theme";

const IntroValidator = /(?::\s*1\.|:1\.|^1\.)/;

const TextAnswer: React.FC<{ answeredQuestion: AnsweredQuestion, noAnswer?: string }> = ({answeredQuestion, noAnswer}) => {
    const [sentences, setSentences] = useState<string[]>([]);

    useEffect(() => {
        if (typeof answeredQuestion.answer === 'string') {
            let localSentences = [];

            if (!!answeredQuestion.answer && answeredQuestion?.answer?.trim()?.toLowerCase() !== 'n/a') {
                if (answeredQuestion.answer.indexOf('\n') !== -1) {
                    const answers = answeredQuestion.answer.replace(/\n+/g, '\n').split('\n');

                    if (/^(?:\d+\.\s*)/g.test(answers[0]))
                        localSentences.push('');
                    answers.forEach((answer) => localSentences.push(answer));
                    setSentences(localSentences);
                } else if (IntroValidator.test(answeredQuestion.answer as string)) {
                    const answers = answeredQuestion.answer.split(IntroValidator);

                    localSentences.push(`${answers[0] + (!!answers[0] ? ':' : '')}`);
                    if (!!answers[1]) {
                        answers[1].split(/(?:^|\.)\s*\d+\./g).forEach((content, index) =>
                            localSentences.push(`${index + 1}.${content + (content.endsWith('.') ? '' : '.')}`)
                        );
                    }
                    setSentences(localSentences);
                } else {
                    setSentences([answeredQuestion.answer]);
                }
            }
        } else if (typeof answeredQuestion.answer === 'number') {
            setSentences([String(answeredQuestion.answer)]);
        }
    }, [answeredQuestion]);

    return (<>
        {!!sentences.length ? (
            sentences?.map((sentence, i) => (!!sentence && (
                <Fragment key={'text-answer-43-' + i}>
                    <Typography fontFamily="Inter">{sentence}</Typography>
                    {(i < sentences.length - 1) && (<Typography>&nbsp;</Typography>)}
                </Fragment>
        )))) : (
            <Typography color={theme.colors.neutral['500']}>
                {noAnswer || NoInfoYet.answer}
            </Typography>
        )}
    </>);
}

export default TextAnswer;