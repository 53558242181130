import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { Button, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import classnames from "classnames";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import theme from "../../../theme";
import DashboardTagModal from "../../modals/dashboards-overview/DashboardTagModal";
import CompaniesMultiAction from "../multi-action/CompaniesMultiAction";

const useStyles = makeStyles((theme) => ({
    row: {
        minWidth: '100%',
        width: 'fit-content',
        height: 'fit-content',
        borderBottom: `1px solid ${theme.colors.neutral['400']}`,
        background: `#fff`,
    },
    header: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['900'],
        textTransform: 'none',
        paddingLeft: 'unset !important',
        "&:hover": {
            background: 'unset',
        }
    },
    lightHeader: {
        fontWeight: '600',
        color: theme.colors.neutral['600'],
    },
    checkbox: {
        minWidth: 24,
        maxWidth: 24,
    },
    cell: {
        minWidth: 180,
        maxWidth: 180,
        minHeight: 60,
        maxHeight: 60,
        padding: '0 10px',
    },
    narrowCell: {
        minWidth: 150,
        maxWidth: 150,
    },
    narrowerCell: {
        minWidth: 50,
        maxWidth: 50,
    },
    customNarrowCell: {
        minWidth: 20,
        maxWidth: 20,
    },
    wideCell: {
        minWidth: 280,
        maxWidth: 280,
    },
    widerCell: {
        minWidth: 380,
        maxWidth: 380,
    },
    fullWidthCell: {
        minWidth: 0,
        maxWidth: 'unset !important',
        width: '100%',
    },
    hiddenCell: {
        display: 'none',
    },
    endCell: {
        minWidth: 4,
        maxWidth: 4,
        padding: 'unset',
    },
    addSector: {
        minWidth: 'unset',
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        color: theme.palette.primary.light,
        textTransform: 'none',
        "&:hover": {
            background: 'unset',
        }
    },
    customCell: {
        minWidth: 240,
        maxWidth: 240,
    },
    sortHeaders: {
        minWidth: 'unset',
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: '600',
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        "&:hover": {
            background: 'unset',
        }
    },
}));

export const TableHeaders = ['Name', 'Website', 'Fit score', 'Stage', 'Sectors', 'Description', 'Location', 'Data from...', 'Deal owner', 'Fundraising', 'Revenue', 'Valuation', 'Margins', '', 'Added', ''];
export const RowActions = ['Share deals', 'Move to', 'Copy deals snippet', 'Add to an investor workspace', 'Download as PDF', 'Remove from workspace', 'Delete'];

const DashboardsTableHeader: React.FC<{
    columns: string[],
    actions?: string[],
    multiAction?: boolean,
    lightHeader?: boolean,
    editableSector?: boolean,
    columnSorted?: string,
    xAdjust?: number,
    onColumnSort?: (column: string) => void,
}> = ({ columns, actions, multiAction, lightHeader, editableSector, columnSorted, xAdjust, onColumnSort }) => {
    const classes = useStyles();
    const [manageTagsOpenModal, setManageTagsOpenModal] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<string>('Added');
    const multiActionRef = useRef<any>(null);

    const props = { actions, xAdjust };

    const showColumn = (column: string) => columns.join(';').includes(column);

    const handleColumnSort = useCallback((column: string) => {
        setSortBy(column);
        onColumnSort?.(column);
    }, [onColumnSort]);

    useEffect(() => {
        if (columnSorted !== undefined)
            setSortBy(columnSorted);
    }, [columnSorted]);

    return (<>
        <Stack direction="row" className={classes.row} alignItems="flex-start" justifyContent="flex-start" ref={multiActionRef}>
            <Stack className={classes.checkbox} />
            {TableHeaders.map((header, i, self) => showColumn(header) && (
                <Fragment key={'dashboads-table-header-74-' + i}>
                    <Stack direction="column" className={classnames(classes.cell,
                        (i === 0) && classes.customCell,
                        (i === 2) && classes.narrowCell,
                        (i === 4) && classes.wideCell,
                        (i === 5) && classes.widerCell,
                        (i === 6) && classes.customCell,
                        (i === self.length - 3) && classes.fullWidthCell,
                        (i === self.length - 2) && classes.narrowCell,
                        (i === self.length - 1) && classes.narrowerCell)}
                        alignItems="flex-start" justifyContent="center" >
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                            {!!onColumnSort && (['Fit score', 'Added'].includes(header)) ? (
                                <Button variant="text"
                                    className={classnames(classes.header, lightHeader && classes.lightHeader)}
                                    onClick={() => handleColumnSort(header)}
                                    disableRipple> {header} </Button>
                            ) : (
                                <Typography className={classnames(classes.header, lightHeader && classes.lightHeader)}>{header}</Typography>
                            )}
                            {editableSector && (header === 'Sectors') && (
                                <Button variant="text"
                                    className={classes.addSector}
                                    onClick={() => setManageTagsOpenModal(true)}
                                    disableRipple> {'Add...'} </Button>
                            )}
                            {!!onColumnSort && (['Fit score', 'Added'].includes(header)) && (<>
                                {sortBy === header ? (
                                    <ArrowDropDownIcon sx={{ fill: theme.palette.primary.main, transform: 'scale(1.5, 2.5)'}} />
                                ) : (
                                    <ChangeHistoryIcon sx={{ fill: theme.colors.neutral['600'],
                                        transform: 'rotate(180deg) scale(0.85, 0.85)', translate: '-10px 1px' }} />
                                )}
                            </>)}
                        </Stack>
                    </Stack>
                    {(header === 'Sectors') && showColumn(header) && (
                        <Stack className={classnames(classes.cell, classes.customNarrowCell)} />
                    )}
                </Fragment>
            ))}
            <Stack className={classnames(classes.cell, classes.endCell)} />
        </Stack>
        {multiAction && (<CompaniesMultiAction {...props} ref={multiActionRef} />)}
        {manageTagsOpenModal && (
            <DashboardTagModal
                open={manageTagsOpenModal}
                onClose={() => setManageTagsOpenModal(false)}
                forManagement
            />
        )}
    </>);
}

export default DashboardsTableHeader;