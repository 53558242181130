import React, { useCallback, useContext, useMemo } from "react";
import { Box, Typography, Stack, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AuthContext } from "../../../contexts/AuthContext";
import { scrollbarStyle } from '../../../shared/dashboard';
import { Dashboard, EmailBody, UnassignedContentType } from "../../../types/files";
import DashboardAssignedContentCard from "../../molecules/dashboard-assigned-content/DashboardAssignedContentCard";
import theme from "../../../theme";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import NoDashboardContent from "../../molecules/dashboard-assigned-content/NoDashboardContent";
import ArrayUtils from "../../../utils/ArrayUtils";
import { useSnackbar } from "notistack";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import { EmailSummary } from "../../../types/search";
import {DashboardContext} from "../../../contexts/DashboardContext";
import { getGroupEmail } from "../../../helpers/authUser";

const useStyles = makeStyles((theme) => ({
    panel: {
        transition: 'width .3s ease',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        width: '100%',
        ...scrollbarStyle
    },
}));

const DashboardContent: React.FC<{ mailedDashboard?: Dashboard; type: UnassignedContentType }> = ({ mailedDashboard, type }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { userGroup } = useContext(AuthContext);
    const { dashboard: ctxDashboard } = useContext(DashboardContext);
    const { queryAnswer } = useDashboardQuery('Email Summaries');

    const dashboard = useMemo(() => mailedDashboard ?? ctxDashboard, [ctxDashboard, mailedDashboard]);

    const filterType = useCallback((emailBody: EmailBody) => {
        switch (type) {
            case UnassignedContentType.OTHER:
                return (emailBody?.type === 'OTHER');
            case UnassignedContentType.CALL:
                return (emailBody?.type === 'CALL');
            case UnassignedContentType.EMAIL:
            case UnassignedContentType.FULL_EMAIL:
            default:
                return (!emailBody?.type || ['EMAIL', 'FULL_EMAIL'].includes(emailBody?.type || ''));
        }
    }, [type]);

    const contents: (EmailBody & {summary : string})[]|undefined = useMemo(() => {
        const summaries = queryAnswer?.answeredQuestion.answer as EmailSummary[];

        return ArrayUtils.sortByDescending(
            dashboard?.emailBodies?.filter(filterType)
                ?.map(emailBody => ({...emailBody,
                    summary: summaries?.find((summary: {id: string|null}) => summary?.id === emailBody?.id)?.summary || ''}))
                ?? []
        , 'createdAt');
    }, [dashboard?.emailBodies, queryAnswer?.answeredQuestion, filterType]);

    if (!dashboard)
        return (<></>);

    return (<>
        <Box className={classes.panel}>
            <Box padding="12px 0" />
            <Stack direction="column" spacing={2} pb={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5-bold" fontFamily="Poppins" marginLeft="16px !important">
                        {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase() + 's'}
                    </Typography>
                    {!!contents?.length && (
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                            <Typography variant="h6-bold" fontSize="1.1rem" color={theme.colors.neutral['500']} fontFamily="Inter">
                                {'Forwarding email: '}
                                <Typography variant="body1"
                                    fontSize="1rem"
                                    fontWeight="bold"
                                    color={theme.colors.cyan['500']}
                                    ml={1}
                                    display="inline">{getGroupEmail(userGroup)}</Typography>
                            </Typography>
                            <CopyToClipboard text={`${getGroupEmail(userGroup)}`}
                                options={{ format: "text/plain" }}
                                onCopy={() => {
                                    enqueueSnackbar(`Successfully copied to clipboard.`,
                                        { anchorOrigin: { vertical: "bottom", horizontal: "right" }, });
                                }}>
                                <IconButton size="small" sx={{ color: theme.colors.cyan['500'] }}>
                                    <ContentCopyIcon sx={{ width: '20px', height: '20px', }} />
                                </IconButton>
                            </CopyToClipboard>
                        </Stack>
                    )}
                </Stack>
                <Box width="100%" overflow="auto" marginLeft="24px !important" sx={{ ...scrollbarStyle }}>
                    <Stack direction="row" spacing={4} alignItems="baseline" justifyContent="flex-start" padding="8px !important">
                        {!!contents?.length ? (contents.map((content, i) =>
                            <DashboardAssignedContentCard
                                content={content}
                                key={'dashboard-content-86-' + i}
                            />
                        )) : (
                            <NoDashboardContent type={type} />
                        )}
                        <div>&nbsp;</div>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    </>);
}

export default DashboardContent;