export default class ArrayUtils {
    static getUniqueObjects(a: Array<any>, key: string) {
        return Array.from(new Set(a.map(e => e[key])))
            .map(keyItem => {
                return a.find(e => e[key] === keyItem)
            });
    }
    static mergeArrays(a1: Array<any>, a2: Array<any>, key: string) {
        const diff = this.getDiff(a1, a2, key);
        diff.added.forEach(x => a1.splice(0, 0, x));
        a2.forEach(x => this.updateObject(a1, x, key));
    }

    static syncArrays(a1: Array<any>, a2: Array<any>, key: string) {
        const diff = this.getDiff(a1, a2, key);
        diff.added.forEach(d => a1.push(d));
        diff.removed.forEach(d => this.removeObject(a1, d, key));
    }

    static updateObject(a: Array<any>, updatedObject: any, key: string) {
        const found = a.find(x => x[key] === updatedObject[key]);
        if (found) {
            Object.assign(found, updatedObject);
        }
    }

    static areEqual(a: Array<any>, b: Array<any>) {
        if (!a || !b)
            return false;
        if (a.length !== b.length)
            return false;

        const containsAll = (arr1: Array<any>, arr2: Array<any>) =>
            arr2.every(arr2Item => arr1.includes(arr2Item));

        return containsAll(a, b) && containsAll(b, a);
    }

    static removeObject(a: Array<any>, object: any, key: string) {
        this.removeItem(a, (x: any) => x[key] === object[key]);
    }

    static removeItem(a: Array<any>, predicate: Function) {
        const idx = a.findIndex(x => predicate(x));
        if (idx === -1) {
            return;
        }
        a.splice(idx, 1);
    }

    static getDiff(a1: Array<any>, a2: Array<any>, key: string): { removed: Array<any>, added: Array<any> } {
        return {
            removed: this.getRemovedDiff(a1, a2, key),
            added: this.getRemovedDiff(a2, a1, key),
        };
    }

    static sortBy(a: Array<any>, key: string): Array<any> {
        return a.sort((x, y) => {
            if (x[key] < y[key]) {
                return -1;
            } else if (x[key] > y[key]) {
                return 1;
            }
            return 0;
        });
    }

    static sortByDescending(a: Array<any>, key: string): Array<any> {
        return a.sort((x, y) => {
            if (x[key] < y[key]) {
                return 1;
            } else if (x[key] > y[key]) {
                return -1;
            }
            return 0;
        });
    }

    static sortByDateCastDescending(a: Array<any>, key: string, defaultValue: Date): Array<any> {
        return a.sort((x, y) => {
            let xValue = x[key];
            if (!xValue) {
                xValue = defaultValue;
            } else if (typeof xValue === 'string') {
                xValue = new Date(xValue);
            }

            let yValue = y[key];
            if (!yValue) {
                yValue = defaultValue;
            } else if (typeof yValue === 'string') {
                yValue = new Date(yValue);
            }

            if (xValue < yValue) {
                return 1;
            } else if (xValue > yValue) {
                return -1;
            }
            return 0;
        });
    }

    static sortByAscending(a: Array<any>, key: string): Array<any> {
        return a.sort((x, y) => {
            if (x[key] > y[key]) {
                return 1;
            } else if (x[key] < y[key]) {
                return -1;
            }
            return 0;
        });
    }

    static findLast<T>(array: T[], predicate: (item: T) => boolean): T | undefined {
        for (let i = array.length - 1; i >= 0; i--) {
            if (predicate(array[i])) {
                return array[i];
            }
        }
        return undefined;
    }

    private static getRemovedDiff(a1: Array<any>, a2: Array<any>, key: string) {
        const ret: any[] = [];
        a1.forEach((e1: any) => {
            const found = a2.find((e2: any) => {
                return e2[key].toString() === e1[key].toString();
            });
            if (!found) {
                ret.push(e1);
            }
        });
        return ret;
    }
}