import { styled, ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Worker } from "@react-pdf-viewer/core";
import { Amplify, Auth } from "aws-amplify";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { BrowserRouter as Router } from "react-router-dom";
import { BreakpointProvider } from "react-socks";
import awsConfig from "../aws-exports";
import App from "../components/App";
import MobileApp from "../components/MobileApp";
import PublicApp from "../components/PublicApp";
import AuthProvider from "../contexts/AuthContext";
import BulkActionsProvider from "../contexts/BulkActionsContext";
import DashboardProvider from "../contexts/DashboardContext";
import DashboardQueriesProvider from "../contexts/DashboardQueriesContext";
import DashboardsProvider from "../contexts/DashboardsContext";
import FileStructureProvider from "../contexts/FileStructureContext";
import GroupSettingsProvider from "../contexts/GroupSettingsContext";
import SearchProvider from "../contexts/SearchContext";
import SearchNavigationProvider from "../contexts/SearchNavigationContext";
import SubscriptionProvider from "../contexts/SubscriptionContext";
import UsersProvider from "../contexts/UsersContext";
import theme from "../theme";
import InboxProvider from "../contexts/InboxContext";

Amplify.configure({
  ...awsConfig,
  Storage: {
    bucket: awsConfig.aws_main_bucket,
    region: awsConfig.aws_project_region,
  },
});

const IntegrationErrorContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-error': {
    width: 'fit-content',
    height: 'fit-content',
    padding: '8px 24px 8px 0',
    borderRadius: 16,
    background: '#fff',
    border: `1px solid ${theme.colors.neutral['300']}`,
    boxShadow: theme.shadows[6],
    transition: '0.5s ease-in-out',
    zIndex: 1800,
  },
}));

const RootContainer = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const assessLoggedInState = () => {
    Auth.currentAuthenticatedUser()
      .then((sess) => {
        setLoggedIn(true);
        setLoading(false);
      })
      .catch(() => {
        setLoggedIn(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    assessLoggedInState();
  }, []);

  if (isMobile) {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <MobileApp />
        </Router>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {!loading && (
          <>
            {loggedIn ? (
              <AuthProvider>
                <FileStructureProvider>
                  <UsersProvider>
                    <SearchNavigationProvider>
                      <SearchProvider>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                          <SnackbarProvider maxSnack={4} Components={{ error: IntegrationErrorContent }}>
                            <Router>
                              <BreakpointProvider>
                                <GroupSettingsProvider>
                                  <DashboardsProvider>
                                    <DashboardProvider>
                                      <DashboardQueriesProvider>
                                        <BulkActionsProvider>
                                          <SubscriptionProvider>
                                            <InboxProvider>
                                              <App />
                                            </InboxProvider>
                                          </SubscriptionProvider>
                                        </BulkActionsProvider>
                                      </DashboardQueriesProvider>
                                    </DashboardProvider>
                                  </DashboardsProvider>
                                </GroupSettingsProvider>
                              </BreakpointProvider>
                            </Router>
                          </SnackbarProvider>
                        </Worker>
                      </SearchProvider>
                    </SearchNavigationProvider>
                  </UsersProvider>
                </FileStructureProvider>
              </AuthProvider>
            ) : (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <SnackbarProvider maxSnack={4}>
                  <Router>
                    <BreakpointProvider>
                      <DashboardsProvider>
                        <DashboardProvider>
                          <DashboardQueriesProvider>
                            <PublicApp />
                          </DashboardQueriesProvider>
                        </DashboardProvider>
                      </DashboardsProvider>
                    </BreakpointProvider>
                  </Router>
                </SnackbarProvider>
              </Worker>
            )}
          </>
        )}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default RootContainer;
