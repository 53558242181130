import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import BulkAddWorkspacesModal from "../modals/home/BulkAddWorkspacesModal";
import CreateEditInvestorModal from "../modals/investors/CreateEditInvestorModal";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'flex',
        width: '90%',
        height: 60,
        overflow: 'hidden',
        padding: '12px 16px',
        borderRadius: 16,
        border: `2px solid ${theme.colors.primary['100']}`,
        background: theme.colors.primary['50'],
        transition: 'all 0.2s ease',
        '&:hover': {
            border: `2px solid ${theme.colors.primary['200']}`,
            background: theme.colors.primary['50'],
            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
        },
    },
    createButton: {
        width: 'auto',
        height: 40,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    label: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 500,
    },
    sublabel: {
        marginLeft: 4,
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.colors.neutral['500'],
    },
}));

const AddInvestorPerson: React.FC<{}> = () => {
    const classes = useStyles();
    const [createWorkspaceModalOpen, setCreateWorkspaceModalOpen] = useState<boolean>(false);
    const [bulkAddWorkspaceModalOpen, setBulkAddWorkspaceModalOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    return (<>
        <Box>
            <Button variant="outlined"
                className={classes.createButton}
                startIcon={<AddCircleOutlineIcon />}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setAnchorEl(e.currentTarget);
                }}
            >{'Add a person'} </Button>
            <Menu open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => setAnchorEl(null)}>
                <MenuItem className={classes.label}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setAnchorEl(null);
                        setCreateWorkspaceModalOpen(true);
                    }}> {'Single investor'} </MenuItem>
                <MenuItem className={classes.label}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setAnchorEl(null);
                        setBulkAddWorkspaceModalOpen(true);
                    }}> {'List of investors'}
                    <Typography className={classes.sublabel} display="inline">
                        {'(via CSV)'}
                    </Typography>
                </MenuItem>
            </Menu>
        </Box>
        {createWorkspaceModalOpen && (
            <CreateEditInvestorModal
                isOpen={createWorkspaceModalOpen}
                onClose={() => {
                    setCreateWorkspaceModalOpen(false);
                }}
            />
        )}
        <BulkAddWorkspacesModal
            isOpen={bulkAddWorkspaceModalOpen}
            onClose={() => setBulkAddWorkspaceModalOpen(false)}
        />
    </>);
}

export default AddInvestorPerson;