import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EmailIcon from '@mui/icons-material/MailOutlineRounded';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, Divider, Fade, IconButton, Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import { defaultGeographies, defaultStages, scrollbarStyle } from "../../../shared/dashboard";
import theme from "../../../theme";
import { InvestmentThesis, Workspace } from "../../../types/files";
import HoverEditable from "../../atoms/HoverEditable";
import MultiSelector from "../../atoms/MultiSelector";
import CreateEditInvestorModal from "../../modals/investors/CreateEditInvestorModal";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        paddingTop: 8,
        paddingLeft: 20,
        overflow: 'hidden',
    },
    scrollable: {
        width: '100%',
        height: '100%',
        marginBottom: '16px !important',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    title: {
        fontFamily: 'Poppins',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
    groupTitle: {
        fontFamily: 'Poppins',
        fontSize: '1.4rem',
        fontWeight: 'bold',
        color: '#000',
    },
    subtitle: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        color: theme.colors.neutral['500'],
    },
    label: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.colors.neutral['500'],
    },
    inputField: {
        minWidth: 'fit-content',
        width: '100%',
        marginBottom: '8px !important',
        "& .MuiOutlinedInput-root": {
            minWidth: 250,
            borderRadius: 8,
            padding: '8px !important',
            "& > input": {
                padding: 'unset !important',
                fontSize: '1rem',
            },
            "& fieldset": {
              borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    icon: {
        height: 20,
        width: 20,
    },
    addtlButton: {
        width: 'fit-content',
        padding: '6px 0 !important',
        textTransform: 'none',
        "&:hover": {
            background: 'transparent !important',
        }
    },
    commonButton: {
        width: 100,
        height: 36,
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
    },
    cancelButton: {
        width: 100,
        height: 36,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const Preferences: React.FC<{
    workspace?: Workspace,
    onSave: (investmentThesis: InvestmentThesis) => Promise<boolean>,
    onCollapse: () => void,
}> = ({ workspace, onSave, onCollapse }) => {
    const classes = useStyles();
    const { tags } = useContext(GroupSettingsContext);
    const { workspace: globalWorkspace, isPublicView } = useContext(DashboardsContext);

    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [selectedStages, setSelectedStages] = useState<string[]>([]);
    const [selectedGeography, setSelectedGeography] = useState<string[]>([]);
    const [addDetails, setAddDetails] = useState<string>();
    const [modifyFields, setModifyFields] = useState<string[] | undefined>(undefined);
    const [createInvestorOpen, setCreateInvestorOpen] = useState<boolean>(false);

    const profileRef = useRef<HTMLDivElement|null>(null);
    const addDetailsRef = useRef<TextFieldProps>(null);

    const debounced = useDebouncedCallback(value => handleSave([value], 'additionalDetails'), 1000, { maxWait: 2000 });

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [globalWorkspace, workspace]);

    const handleSave = useCallback((selections: string[], type: string) => {
        const focusArea = { tags: type === 'focusArea' ? selections : selectedTags };
        const stage = { stages: type === 'stage' ? selections : selectedStages };
        const geography = { regions: type === 'geography' ? selections : selectedGeography };
        const additionalDetails = { freeText: type === 'additionalDetails' ? selections[0] : addDetails };

        switch (type) {
            case 'focusArea':
                setSelectedTags(focusArea.tags ?? []);
                break;
            case 'stage':
                setSelectedStages(stage.stages ?? []);
                break;
            case 'geography':
                setSelectedGeography(geography.regions ?? []);
                break;
            case 'additionalDetails':
                setAddDetails(additionalDetails.freeText || '');
                break;
        }
        onSave?.({ focusArea, stage, geography,  additionalDetails } as InvestmentThesis);
    }, [selectedTags, selectedStages, selectedGeography, addDetails, onSave]);

    useEffect(() => {
        if (!!localWorkspace) {
            setSelectedTags(localWorkspace.investmentThesis?.focusArea?.tags ?? []);
            setSelectedStages(localWorkspace.investmentThesis?.stage?.stages ?? []);
            setSelectedGeography(localWorkspace.investmentThesis?.geography?.regions ?? []);
            setAddDetails(localWorkspace.investmentThesis?.additionalDetails?.freeText || '');
        }
    // eslint-disable-next-line
    }, [localWorkspace]);

    if (!localWorkspace)
        return (<></>);

    return (<>
        <Stack className={classes.container} alignItems="space-between" justifyContent="stretch">
            <Stack direction="column" spacing={2} alignItems="flex-start" justifyContent="stretch" width="100%" height={`calc(100% - 4px)`}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                    <Stack direction="column" spacing="4px" alignItems="flex-start" justifyContent="flex-start">
                        <Stack direction="row" spacing="4px" alignItems="flex-start" justifyContent="flex-start">
                            <PersonOutlineOutlinedIcon sx={{ width: 32, height: 32, fill: theme.palette.primary.main }} />
                            <Typography component="span" className={classes.groupTitle} display="inline">
                                {`${localWorkspace?.name}'s Profile`}
                            </Typography>
                        </Stack>
                        <HoverEditable variant="small"
                            onClick={() => {
                                setCreateInvestorOpen(true);
                                setModifyFields(['email']);
                            }} disableEdit={isPublicView}>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" ml="6px">
                                <EmailIcon sx={{ width: 24, height: 24, fill: 'rgba(0, 0, 0, 0.3)' }} />
                                <Typography className={classes.label}>
                                    {localWorkspace?.email}
                                </Typography>
                            </Stack>
                        </HoverEditable>
                    </Stack>
                    <Box mr="16px !important">
                        <IconButton onClick={(e) => {
                            e.stopPropagation();
                            onCollapse();
                        }}> <ArrowForwardIosIcon /> </IconButton>
                    </Box>
                </Stack>
                <Divider sx={{ width: '100%' }} />
                <Fade in timeout={300}>
                    <Stack direction="row" className={classes.scrollable} alignItems="flex-start">
                        <Stack direction="column" spacing={2} alignItems="flex-start" justifyContent="flex-start"
                            width="100%" height="100%" ref={profileRef}>
                            <MultiSelector
                                mainOptions={tags}
                                selections={selectedTags}
                                label="Sectors of interest"
                                onSelection={(selections) => handleSave(selections as string[], 'focusArea')}
                                withSearch />
                            <MultiSelector
                                mainOptions={defaultStages}
                                selections={selectedStages}
                                label="Stage"
                                onSelection={(selections) => handleSave(selections as string[], 'stage')}
                                withSearch />
                            <MultiSelector
                                mainOptions={defaultGeographies}
                                selections={selectedGeography}
                                label="Geography"
                                onSelection={(selections) => handleSave(selections as string[], 'geography')}
                                withSearch />
                            <Stack direction="column" spacing={1} alignItems="flex-start" width="100%">
                                <Typography fontFamily="Poppins" fontSize="1rem" fontWeight="bold" color={theme.colors.neutral['800']}>
                                    {`Additional details`}
                                </Typography>
                                <Typography fontSize="0.8rem" color={`${theme.colors.neutral['500']} !important`}>
                                    {`Add notes or additional details on ${localWorkspace?.name}'s preferences.`}
                                    <br />
                                    {'This will help refine the top matches.'}
                                </Typography>
                            </Stack>
                            <TextField variant="outlined"
                                className={classes.inputField}
                                placeholder={''}
                                inputRef={addDetailsRef}
                                defaultValue={addDetails}
                                onChange={(e) => debounced(e.target.value)}
                                rows={5}
                                multiline
                                fullWidth
                            />
                            <Box />
                        </Stack>
                    </Stack>
                </Fade>
            </Stack>
        </Stack>
        {createInvestorOpen && (
            <CreateEditInvestorModal
                isOpen={createInvestorOpen}
                workspaceId={localWorkspace?.id}
                fields={modifyFields}
                onClose={() => setCreateInvestorOpen(false)}
            />
        )}
    </>);
}

export default Preferences;