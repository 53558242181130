import LaunchIcon from '@mui/icons-material/Launch';
import { Box, CircularProgress, IconButton, Link, Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ReactComponent as PDFIcon } from "../../../assets/icons/pdfNew.svg";
import { ReactComponent as WorldGridIcon } from "../../../assets/icons/world.svg";
import { AuthContext } from '../../../contexts/AuthContext';
import { DashboardContext } from '../../../contexts/DashboardContext';
import { FileStructureContext } from '../../../contexts/FileStructureContext';
import useFileUploader from '../../../hooks/useFileUploader';
import useRoute from '../../../hooks/useRoute';
import { updateDashboardFunc, updateFileFunc } from '../../../lib/helper';
import { scrollbarStyle } from '../../../shared/dashboard';
import theme from '../../../theme';
import { CompanyFile, Dashboard } from '../../../types/files';
import CompanyMiniDeckViewer from '../dashboard-deck/CompanyMiniDeckViewer';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        padding: 'unset',
        border: 'unset',
        background: 'transparent',
        transition: 'all 300ms ease',
        "&:hover": {
            border: `unset !important`,
            background: 'transparent !important',
            boxShadow: 'unset !important',
        },
    },
    answerContainer: {
        width: '100%',
        height: 'auto',
        marginLeft: 4,
        paddingLeft: 4,
        paddingRight: 16,
        border: `1px transparent solid`,
        borderRadius: 12,
    },
    answerBlock: {
        width: '100%',
        height: '48px',
        paddingTop: 8,
        paddingBottom: 8,
        color: '#666666',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    answerHover: {
        width: '96%',
        marginRight: 2,
        '&:hover': {
            border: `1px ${theme.colors.neutral['400']} solid`,
            borderRadius: 12,
        }
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginLeft: 8,
    },
    deck: {
        color: '#666666',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    url: {
        display: 'inline-block',
        color: '#666666',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        lineClamp: 1,
        maxWidth: '22ch',
        "&:hover": {
            textDecoration: 'none !important',
        },
    },
    editBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxHeight: 910,
        overflow: 'hidden',
    },
    editIcon: {
        width: 32,
        height: 32,
        padding: 'unset',
        "& > .MuiSvgIcon-root": {
            fill: theme.colors.neutral['600'],
        }
    },
    icon: {
        width: 24,
        height: 24,
        fill: '#7bd4d4',
    },
    answerEditContainer: {
        width: '98%',
        padding: 8,
        backgroundColor: "#fff",
        borderRadius: 10,
        border: `1px ${theme.colors.neutral['500']} solid`,
    },
    inputField: {
        "& .MuiOutlinedInput-root": {
            width: '100%',
            padding: 'unset',
            "& > input": {
                fontSize: '1rem',
                padding: 'unset',
            },
        },
    },
    saveButton: {
        padding: 'unset',
        color: theme.palette.primary.main,
        "& > svg": {
            width: 28,
            height: 28,
        },
    },
    cancelButton: {
        padding: 'unset',
        color: theme.colors.neutral['500'],
        "& > svg": {
            width: 28,
            height: 28,
        },
    },
}));

const DeckLink: React.FC<{ dashboard: Dashboard }> = ({ dashboard }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { fileStructure } = useContext(FileStructureContext);
    const { isPublicView } = useContext(DashboardContext);
    const { docsendIngestion } = useFileUploader({});
    const { redirectToDashboard } = useRoute();
    const [isEditingAnswer, setIsEditingAnswer] = useState<boolean>(false);
    const [editAnswer, setEditAnswer] = useState<string | undefined >(undefined);
    const [hoverAnswer, setHoverAnswer] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [deckModalOpen, setDeckModalOpen] = useState<boolean>(false);
    const inputValue = useRef<TextFieldProps>(null);

    const sanitizeUrl = (originUrl: string) => originUrl.replace(/^https?:\/\//, '').replace(/^www\./, '');

    const files = useMemo(() =>
        fileStructure.filter(file => !file.isDirectory && file.dashboardId === dashboard?.id) as CompanyFile[]
    , [dashboard, fileStructure]);

    const defaultFile = useMemo(() => files.find(file => file.isDeck) ?? files[0], [files]);

    const saveEditAnswer = useCallback(async (answer: string) => {
        if (!/\.pdf$/i.test(answer) && /^https?:\/\/(www\.)?docsend\.com/i.test(answer)) {
            setSaving(true);
            docsendIngestion(dashboard, answer).then(async (success) => {
                if (success) {
                    await updateDashboardFunc({ ...dashboard, externalLinks: [answer] });
                    await Promise.all(files?.filter(f => f.isDeck)?.map(dF =>
                        updateFileFunc({
                            id: dF.id,
                            group: userGroup,
                            documentCategory: dF.documentCategory,
                            status: dF.status,
                            dashboardId: dF.dashboardId,
                            marketMetrics: dF.marketMetrics,
                            isDeck: false,
                        })
                    ));
                    dashboard.externalLinks = [answer];
                }
                setIsEditingAnswer(false);
                setEditAnswer(undefined);
                setSaving(false);
            });
        } else {
            setIsEditingAnswer(false);
            setEditAnswer(undefined);
            setSaving(false);
        }
    }, [dashboard, docsendIngestion, files, userGroup]);

    return (<>
        <Box className={classes.masonryElement}
            onMouseEnter={() => setHoverAnswer(!isPublicView)}
            onMouseLeave={() => setHoverAnswer(false)}>
            <Stack direction="column" alignItems="flex-start" width="100%">
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start" height="32px">
                    <Typography className={classes.title}>{'Latest Deck'}</Typography>
                    {saving ? (<CircularProgress size={24} />) : hoverAnswer && (
                        <IconButton size="small"
                            className={classes.editIcon}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsEditingAnswer(true);
                                setEditAnswer(!!dashboard.externalLinks?.length
                                    ? sanitizeUrl(dashboard?.externalLinks?.[0])
                                    : !!defaultFile ? defaultFile.name : '');
                            }} disableRipple>
                            <EditOutlinedIcon fontSize="small" />
                        </IconButton>
                    )}
                </Stack>
                {!isEditingAnswer && (
                    <Stack direction="row" justifyContent="space-between" py="6px"
                        className={classnames(classes.answerContainer, hoverAnswer && classes.answerHover)}
                        onClick={!dashboard.externalLinks?.length ? (e) => {
                            e.stopPropagation();
                            setIsEditingAnswer(true);
                            setEditAnswer(!!dashboard.externalLinks?.length
                                ? sanitizeUrl(dashboard?.externalLinks?.[0])
                                : !!defaultFile ? defaultFile.name : '');
                        } : undefined}
                        onDoubleClick={!!dashboard.externalLinks?.length ? (e) => {
                            e.stopPropagation();
                            setIsEditingAnswer(true);
                            setEditAnswer(!!dashboard.externalLinks?.length
                                ? sanitizeUrl(dashboard?.externalLinks?.[0])
                                : !!defaultFile ? defaultFile.name : '');
                        } : undefined}>
                        {!!dashboard.externalLinks?.length ? (
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent={hoverAnswer ? 'space-between' : 'flex-start'} width="100%">
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" width="100%">
                                    <WorldGridIcon className={classes.icon} fill="#7bd4d4" />
                                    <Typography component="a"
                                        className={classes.url}
                                        href={dashboard?.externalLinks?.[0]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ maxWidth: `${hoverAnswer ? 12 : 14}ch !important`}}>
                                        {sanitizeUrl(dashboard?.externalLinks?.[0])}
                                    </Typography>
                                </Stack>
                                {hoverAnswer && (
                                    <IconButton disableRipple
                                        onClick={() => !!defaultFile ? setDeckModalOpen(true)
                                            : window.open(dashboard?.externalLinks?.[0], '_self', 'noopener, noreferrer')}
                                        sx={{ padding: 'unset !important'}}>
                                        <LaunchIcon className={classes.icon} sx={{ fill: theme.colors.neutral['500'] }} />
                                    </IconButton>
                                )}
                            </Stack>
                        ) : (!!defaultFile) ? (
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent={hoverAnswer ? 'space-between' : 'flex-start'} width="100%"
                                onMouseEnter={() => setHoverAnswer(true)}>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" width="100%">
                                    <PDFIcon className={classes.icon} fill="#7bd4d4" />
                                    <Link onClick={() => redirectToDashboard(dashboard.id, ['files', defaultFile.id].join('/'))}
                                        underline="hover" target="_self" sx={{ cursor: 'pointer' }}>
                                        <Typography className={classes.url} sx={{ maxWidth: `${hoverAnswer ? 12 : 14}ch !important`}}>
                                            {defaultFile.name}
                                        </Typography>
                                    </Link>
                                </Stack>
                                {hoverAnswer && (
                                    <IconButton disableRipple
                                        onClick={() => setDeckModalOpen(true)}
                                        sx={{ padding: 'unset !important'}}>
                                        <LaunchIcon className={classes.icon} sx={{ fill: theme.colors.neutral['500'] }} />
                                    </IconButton>
                                )}
                            </Stack>
                        ) : (<Typography className={classes.url} color="#666666 !important">{'N/A'}</Typography>)}
                    </Stack>
                )}
            </Stack>
            {isEditingAnswer && (
                <Stack direction="row" className={classes.answerEditContainer} alignItems="center" justifyContent="space-between">
                    <TextField variant="outlined"
                        className={classnames('no-border', classes.inputField)}
                        inputProps={{ style: { height: '100%' }, className: 'no-fade' }}
                        style={{ width: '100%', height: '100%', justifyContent: 'center' }}
                        defaultValue={editAnswer}
                        placeholder={'Add answer...'}
                        inputRef={inputValue}
                        rows={1}
                    />
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" spacing="2px" alignItems="center" justifyContent="flex-end">
                            <IconButton className={classes.saveButton}
                                onClick={() => saveEditAnswer(inputValue.current?.value as string)}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton className={classes.cancelButton}
                                onClick={() => {
                                    setIsEditingAnswer(false);
                                    setEditAnswer(undefined);
                                }}>
                                <CancelIcon />
                            </IconButton>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Box>
        {deckModalOpen && (
            <CompanyMiniDeckViewer
                open={deckModalOpen}
                defaultDeck={defaultFile}
                externalDeck={dashboard?.externalLinks?.[0]}
                onClose={() => setDeckModalOpen(false) } />
        )}
    </>);
}

export default DeckLink;
