import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Breadcrumbs, Container, Divider, Link, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { DashboardContext } from "../../contexts/DashboardContext";
import { DashboardsContext } from "../../contexts/DashboardsContext";
import { GroupSettingsContext } from "../../contexts/GroupSettingsContext";
import useRoute from "../../hooks/useRoute";
import { getPublicWorkspaceFunc } from "../../lib/helper";
import theme from "../../theme";
import { Workspace } from "../../types/files";
import storage from "../../utils/storage";
import CampainSection from "../atoms/CampainSection";
import HoverEditable from "../atoms/HoverEditable";
import CreateEditInvestorModal from "../modals/investors/CreateEditInvestorModal";
import DashboardsSearchOrCreate from "../molecules/dashboards-search-create/DashboardsSearchOrCreate";
import WorkspaceActions from "../molecules/multi-action/WorkspaceActions";
import InvestmentThesis from "../organisms/workspace/InvestmentThesis";
import WorkspaceAllowAccess from "../organisms/workspace/WorkspaceAllowAccess";
import PublicWorkspaceNotFound from "../organisms/workspace/WorkspaceNotFound";
import { FallbackLoading } from "../templates/loader";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EmailIcon from '@mui/icons-material/MailOutlineRounded';

type WorkspaceAccessType = {
    iss?: string | null,
    sub: string,
    exp: number | null,
};

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        height: '100%',
        maxWidth: 'unset !important',
        overflowY: 'hidden',
    },
    header: {
        width: '100%',
        height: 'fit-content',
        paddingTop: 16,
        opacity: 1,
        transition: 'height .3s ease-in-out, opacity .3s ease-in-out',
    },
    title: {
        fontFamily: 'Poppins',
        fontSize: '2.2rem',
        fontWeight: 'bolder',
    },
    link: {
        "&:hover": {
            textDecoration: 'underline',
            cursor: 'pointer',
        }
    },
    next: {
        width: 14,
        height: 14,
    },
    breadcrumbs: {
        "& .MuiBreadcrumbs-separator, & a": {
            fontFamily: 'Inter',
            fontSize: '1em !important',
            color: theme.colors.cyan['500'],
            textDecoration: 'none !important',
            cursor: 'auto',
        },
        '& li:first-child a:hover': {
            textDecoration: 'underline !important',
            cursor: 'pointer',
        },
    },
    inputField: {
        "& .MuiOutlinedInput-root": {
            minWidth: '120px',
            height: '50px',
            borderRadius: 20,
            "& > input": {
                fontSize: 16,
            },
            "&:hover fieldset": {
              borderColor: 'darkgray !important',
            },
            "&.Mui-focused fieldset": {
              borderColor: 'black !important',
            },
        },
    },
    mainLabel: {
        fontFamily: 'Inter',
        fontSize: '2rem',
        fontWeight: 700,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineClamp: 1,
        whiteSpace: 'nowrap',
    },
    email: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['500'],
    },
    layoutIcons: {
        minWidth: '52px',
        height: '48px',
        borderRadius: '4px',
    },
    selectedIcon: {
        backgroundColor: '#007681',
    },
    layoutIcon: {
        height: 20,
        width: 20,
    },
    campain: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['600'],
    },
    signUpButton: {
        minWidth: 180,
        width: 'fit-content',
        height: 40,
        padding: '6px 10px',
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
}));

const WorkspacePage: React.FC<{ publicWorkspace?: boolean }> = ({publicWorkspace}) => {
    const classes = useStyles();
    const history = useHistory();
    const params: {[key: string]: string} = useParams();
    const { userGroup } = useContext(AuthContext);
    const { loaded: settingsLoaded, workspaces } = useContext(GroupSettingsContext);
    const { dashboards, workspace, isPublicView,
        setPublicView, setWorkspace, setExternalDashboards, setDashboardsLoaded } = useContext(DashboardsContext);
    const { setPublicView: setPublicDashboardView } = useContext(DashboardContext);
    const { getURLFrom, redirectFromWorkspace } = useRoute();

    const [search, setSearch] = useState<string>("");
    const [loaded, setLoaded] = useState<boolean>(false);
    const [modifyFields, setModifyFields] = useState<string[] | undefined>(undefined);
    const [checkedAccess, setCheckedAccess] = useState<boolean>(false);
    const [hasPublicAccess, setWithPublicAccess] = useState<boolean>(false);
    const [createInvestorOpen, setCreateInvestorOpen] = useState<boolean>(false);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const isInvalidPublicWorkspace = useMemo(() =>
        loaded && publicWorkspace && (!workspace || workspace?.isPublic === false)
    , [loaded, publicWorkspace, workspace]);

    const isValidatingPublicWorkspace = useMemo(() =>
        loaded && (!userGroup && publicWorkspace) && (workspace?.isPublic) && (!checkedAccess || !hasPublicAccess)
    , [checkedAccess, hasPublicAccess, loaded, publicWorkspace, userGroup, workspace?.isPublic]);

    const handleStoreAccess = useCallback((workspace?: Workspace) => {
        if (!workspace)
            return;

        const stream = storage.getItem('config.workspace-public');
        const issuer = workspace.email || '';
        const accessData: WorkspaceAccessType = {
            iss: issuer,
            sub: workspace.id,
            exp: null,
        };

        if (!!stream) {
            const accessList = (JSON.parse(atob(stream)) ?? []) as WorkspaceAccessType[];
            const lookUpAccess = accessList.find(access => (access.iss === issuer && access.sub === workspace.id));

            if (!lookUpAccess) {
                accessList.push(accessData);
                storage.setItem('config.workspace-public', btoa(JSON.stringify(accessList)));
            }
        } else {
            storage.setItem('config.workspace-public', btoa(JSON.stringify([accessData])));
        }
    }, []);

    const handleVerifyAccess = useCallback((workspace?: Workspace) => {
        if (!workspace)
            return;

        timerRef.current = setTimeout(() => {
            const stream = storage.getItem('config.workspace-public');
            const issuer = workspace.email || '';

            if (!!stream) {
                const accessList = (JSON.parse(atob(stream)) ?? []) as WorkspaceAccessType[];
                const lookUpAccess = accessList.find(access => (access.iss === issuer && access.sub === workspace.id));

                if (!!lookUpAccess) {
                    if (!lookUpAccess.iss || (Number(lookUpAccess.exp) > Math.floor(Date.now() / 1000)))
                        setWithPublicAccess(true);
                    if (!lookUpAccess.iss || !!lookUpAccess.exp)
                        setCheckedAccess(true);
                }
            }

            setLoaded(true);
            clearTimeout(timerRef.current);
        }, 500);
    }, []);

    const handleConfirmAccess = useCallback((email: string) => {
        timerRef.current = setTimeout(() => {
            const stream = storage.getItem('config.workspace-public');

            if (!!stream) {
                const accessList = (JSON.parse(atob(stream)) ?? []) as WorkspaceAccessType[];
                const lookUpAccess = accessList.find(access => (access.iss === email && access.sub === workspace?.id));

                if (!!lookUpAccess) {
                    lookUpAccess.exp = Math.floor(Date.now() / 1000) + (24 * 60 * 60);
                    storage.setItem('config.workspace-public', btoa(JSON.stringify(accessList)));
                    setWithPublicAccess(true);
                }
                setCheckedAccess(true);
            }
            clearTimeout(timerRef.current);
        }, 1000);
    }, [workspace]);

    useEffect(() => {
        setPublicView(!!publicWorkspace);
        setPublicDashboardView(false);
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const workspaceId = params.workspaceId;
        const lookUpWorkspace = workspaces.find(workspace => workspace.id === workspaceId);

        if (publicWorkspace) {
            if (!!lookUpWorkspace) {
                setPublicView(false);
                history.push(`${history.location.pathname.replace('/public/', '/')}${history.location.search}`);
            } else {
                getPublicWorkspaceFunc({
                    id: workspaceId,
                    group: getURLFrom()?.originGroup || userGroup,
                }).then((data) => {
                    setExternalDashboards(data?.dashboards ?? []);
                    setDashboardsLoaded(true);
                    setWorkspace(data?.workspace || null);
                    if (!!data?.workspace) {
                        handleStoreAccess(data?.workspace);
                        handleVerifyAccess(data?.workspace);
                    } else {
                        setLoaded(true);
                    }
                });
            }
        } else if (settingsLoaded) {
            if (!!workspaceId) {
                setWorkspace(lookUpWorkspace || null);
                setLoaded(true);
            } else {
                setWorkspace(null);
                setLoaded(true);
            }
        }
    // eslint-disable-next-line
    }, [publicWorkspace, settingsLoaded, params.workspaceId]);

    if (!loaded)
        return (<FallbackLoading />);

    if (isInvalidPublicWorkspace)
        return (<PublicWorkspaceNotFound />);

    if (isValidatingPublicWorkspace)
        return (<WorkspaceAllowAccess invalid={checkedAccess && !hasPublicAccess} onConfirm={handleConfirmAccess} />);

    return (<>
        <Container className={classes.rootContainer}>
            <Stack direction="column" spacing={1} width="100%" height="100%">
                <Stack direction="row" className={classes.header} spacing={3} alignItems="center" justifyContent="space-between"
                    my={isPublicView && !userGroup ? '12px' : undefined}>
                    <Stack direction="column" alignItems="flex-start" minWidth="15%">
                        {isPublicView ? (!!userGroup) && (
                            <Breadcrumbs className={classes.breadcrumbs}
                                separator={<ArrowForwardIosIcon className={classes.next} />}>
                                    {[
                                        <Link onClick={redirectFromWorkspace}
                                            key="dashboard-title-88">{'Back to my workspace'}</Link>,
                                    ]}
                            </Breadcrumbs>
                        ) : (
                            <Breadcrumbs className={classes.breadcrumbs}
                                separator={<ArrowForwardIosIcon className={classes.next} />}>
                                    {[
                                        <Link onClick={redirectFromWorkspace}
                                            key="dashboard-title-88">{'All network'}</Link>,
                                        <Link key="dashboard-title-89">{workspace?.name}</Link>,
                                    ]}
                            </Breadcrumbs>
                        )}
                        <HoverEditable variant="large"
                            onClick={() => {
                                setCreateInvestorOpen(true);
                                setModifyFields(['investor name']);
                            }} disableEdit={publicWorkspace}>
                            <Stack direction="row" spacing="4px" alignItems="center" justifyContent="flex-start">
                                <PersonOutlineOutlinedIcon sx={{ width: 32, height: 32, fill: theme.palette.primary.main }} />
                                <Typography className={classes.mainLabel} fontSize="2rem">
                                    {workspace?.name}
                                </Typography>
                            </Stack>
                        </HoverEditable>
                        <HoverEditable variant="small"
                            onClick={() => {
                                setCreateInvestorOpen(true);
                                setModifyFields(['email']);
                            }} disableEdit={publicWorkspace}>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" ml="3px">
                                <EmailIcon sx={{ width: 24, height: 24, fill: 'rgba(0, 0, 0, 0.3)' }} />
                                <Typography className={classes.email}>
                                    {workspace?.email}
                                </Typography>
                            </Stack>
                        </HoverEditable>
                    </Stack>
                    {(isPublicView || !!dashboards.length) && (<>
                        <Box width="600px">
                            <DashboardsSearchOrCreate search
                                onSearch={(value) => setSearch(value)} />
                        </Box>
                        {isPublicView ? (!userGroup ? (<CampainSection />) : (
                            <Stack alignItems="flex-end" width="auto" pr={2}>
                                <Typography fontFamily="Inter" fontSize="1rem" fontWeight={500} color={theme.palette.primary.main}>
                                    {'This workspace was shared by an external user.'}
                                </Typography>
                            </Stack>
                        )) : (
                            <Stack direction="row" spacing={2} alignItems="center">
                                {/* <WorkspaceShare /> */}
                                {/* <WorkspaceShareEmail /> */}
                                <WorkspaceActions />
                            </Stack>
                    )}</>)}
                </Stack>
                <Divider />
                <InvestmentThesis search={search} />
            </Stack>
        </Container>
        {createInvestorOpen && (
            <CreateEditInvestorModal
                isOpen={createInvestorOpen}
                workspaceId={workspace?.id}
                fields={modifyFields}
                onClose={() => setCreateInvestorOpen(false)}
            />
        )}
    </>);
}

export default WorkspacePage;