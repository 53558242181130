import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import AddInvestorPerson from "../atoms/AddInvestorPerson";
import IntegrationIcons from "../atoms/IntegrationIcons";
import WorkspacesSearchOrCreate from "../molecules/workspaces-search-create/WorkspacesSearchOrCreate";
import InvestorsTable from "../organisms/investors/InvestorsTable";
import NoInvestorsYetView from "../molecules/workspaces-empty/NoInvestorsYetView";

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        height: '100%',
        maxWidth: 'unset !important',
        overflowY: 'hidden',
    },
}));

export const collectionKey = 'investors';

const InvestorsPage: React.FC<{}> = () => {
    const classes = useStyles();
    const [search, setSearch] = useState<string>("");
    const [hasInvestor, setHasInvestor] = useState<boolean|null>(null);

    return (<>
        <Container className={classes.rootContainer}>
            <Stack direction="column" spacing={2} pt={4} pb={2}>
                <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" width="100%">
                    <Typography variant="h4-bold">{`Network`}</Typography>
                    <Box width="600px">
                        <WorkspacesSearchOrCreate search onSearch={(value) => setSearch(value)} />
                    </Box>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                        {hasInvestor && (<AddInvestorPerson />)}
                        <IntegrationIcons />
                    </Stack>
                </Stack>
                <Divider sx={{ width: '100%' }} />
                <InvestorsTable collectionKey={collectionKey} search={search} onHasInvestor={setHasInvestor} withFilter />
                {!hasInvestor && (<NoInvestorsYetView />)}
            </Stack>
        </Container>
    </>);
}

export default InvestorsPage;