import React, { useCallback, useContext, useEffect, useState } from "react";
import { Autocomplete, Box, Button, CircularProgress, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { green, grey } from "@mui/material/colors";
import { Dashboard } from "../../../../../types/files";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { getUserEmail } from "../../../../../helpers/authUser";
import { createDashboardFunc, getDashboards, updateDashboardFunc } from "../../../../../lib/helper";
import { LoadingButton } from "@mui/lab";
import { v4 as uuidv4 } from "uuid";
import { FileStructureContext } from "../../../../../contexts/FileStructureContext";
import { RenderHighlightContentProps } from "@react-pdf-viewer/highlight";
import { SaveDashboardItemType } from "../../../../../types/common";
import { base64ToFile, getS3DashboardScreenshotMetadata, s3Upload } from "../../../../../helpers/s3";
import { useSnackbar } from "notistack";
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrayUtils from "../../../../../utils/ArrayUtils";


const useStyles = makeStyles((theme) => ({
    container: {
        position: "absolute",
        top: 15,
        right: 80,
        borderRadius: 10,
        padding: 8,
        zIndex: 1,
        width: 350
    },
    detailsContainer: {
        width: "100%",
        padding: "5px",
        borderRadius: 10,
        border: `1px solid ${grey[500]}`,
        marginBottom: "15px"
    },
    addContainer: {
        padding: "5px",
    },
    addNewButton: {
        width: "100%",
        backgroundColor: theme.palette.info.main
    },
    addButton: {
        width: 100,
        backgroundColor: theme.palette.info.main
    }
}));

const AddToDashboardPane: React.FC<{
    loading: boolean,
    documentKey: string,
    page: number,
    closeDashboardPane: () => void,
    saveType: SaveDashboardItemType,
    keywords?: string[],
    highlightData?: RenderHighlightContentProps,
    screenCapture?: string,
    positionTop?: string,
    url?: string,
}> = ({ loading, documentKey, page, closeDashboardPane, saveType, url, keywords = [], highlightData = null, screenCapture = null, positionTop = null }) => {
    const classes = useStyles();

    const [dashboards, setDashboards] = useState<Dashboard[]>([]);
    const [selectedDashboard, setSelectedDashboard] = useState<Dashboard | null>(null);
    const [newDashboardName, setNewDashboardName] = useState<string>("");
    const [loadingAddTo, setLoadingAddTo] = useState<boolean>(false);
    const [loadingCreateNew, setLoadingCreateNew] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [isTitleEdit, setIsTitleEdit] = useState<boolean>(false);
    const [isAddNew, setIsAddNew] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const { user, userGroup } = useContext(AuthContext);

    const { fileStructure } = useContext(FileStructureContext);

    const { enqueueSnackbar } = useSnackbar();

    const getFileName = useCallback((): string => {
        if (!documentKey || !fileStructure) {
            return "";
        }

        for (var file of fileStructure) {
            if (file.key === documentKey) {
                return file.name;
            }
        }

        return "";
    }, [documentKey, fileStructure]);

    const getFileId = useCallback((): string => {
        if (!documentKey || !fileStructure) {
            return "";
        }

        for (var file of fileStructure) {
            if (file.key === documentKey) {
                return file.id;
            }
        }

        return "";
    }, [documentKey, fileStructure]);

    useEffect(() => {
        getDashboards(userGroup).then(
            (data: Dashboard[]) => {
                // TODO: dashboard not sorted by 'createdAt'
                setDashboards(ArrayUtils.sortBy(data, 'title'));
            });
    }, [userGroup]);

    useEffect(() => {
        switch (saveType) {
            case SaveDashboardItemType.Page:
                setTitle(`Page ${page + 1} - ${getFileName()}`);
                break;

            case SaveDashboardItemType.Text:
                setTitle(`Selection from: ${getFileName()}`);
                break;

            case SaveDashboardItemType.ScreenCapture:
                setTitle(`Screenshot from: ${getFileName()}`);
                break;

            default:
                setTitle("None");
                break;
        }
    }, [saveType, page, getFileName, highlightData]);

    useEffect(() => {
        if (!loading) {
            const selectElement = document.querySelector("[data-id='add-to-dashboard-select'");

            if (selectElement) {
                (selectElement as HTMLElement).focus();
            }
        }
    }, [loading]);

    const add = async () => {
        if (isAddNew && newDashboardName) {
            await createNewDashboard();
        } else if (selectedDashboard) {
            await addToDashboard();
        }
    }

    const cancel = () => {
        closeDashboardPane();
        highlightData?.cancel();
    }

    const addToDashboard = async () => {
        setLoadingAddTo(true);

        const newDashboardDetails: any = { ...selectedDashboard };

        newDashboardDetails.updatedAt = new Date().toISOString();

        try {
            await appendItemToDashboard(newDashboardDetails);
            const isSuccess: boolean = await updateDashboardFunc(newDashboardDetails);

            setLoadingAddTo(false);
            if (isSuccess) {
                setIsSuccess(true);
                setTimeout(() => {
                    closeDashboardPane();
                    highlightData?.cancel();
                }, 2000);
            } else {
                showErrorNotification(newDashboardDetails.title, false);
            }
        } catch (err) {
            setLoadingAddTo(false);
            showErrorNotification(newDashboardDetails.title, false);
        }
    }

    const createNewDashboard = async () => {
        setLoadingCreateNew(true);

        const newDashboardDetails: Dashboard = {
            createdAt: (new Date()).toISOString(),
            updatedAt: (new Date()).toISOString(),
            userId: getUserEmail(user!),
            email: getUserEmail(user),
            group: userGroup,
            project: userGroup,
            id: uuidv4(),
            title: newDashboardName,
            documents: [],
            selections: [],
            screenshots: [],
            notes: [],
            lastUpdatedBy: "",
            shouldRefresh: false,
            refreshData: null,
            summary: "",
            status: "",
        };

        try {
            await appendItemToDashboard(newDashboardDetails);
            const isSuccess: boolean = await createDashboardFunc(newDashboardDetails);

            setLoadingCreateNew(false);
            if (isSuccess) {
                setIsSuccess(true);
                setTimeout(() => {
                    closeDashboardPane();
                    highlightData?.cancel();
                }, 2000);
                // showSuccessNotification(newDashboardDetails.title.toString(), true);
            } else {
                showErrorNotification(newDashboardDetails.title.toString(), true);
            }
        } catch (err) {
            setLoadingCreateNew(false);
            showErrorNotification(newDashboardDetails.title.toString(), true);
        }
    }

    const appendItemToDashboard = async (item: Dashboard) => {
        if (saveType === SaveDashboardItemType.Page) {
            if (!item.documents) {
                item.documents = [];
            }

            const documentName = getFileName();

            item.documents.push({
                id: uuidv4(),
                title: title,
                searchTerms: keywords,
                searchTitle: [documentName],
                pages: [page],
                key: documentKey,
                description: documentName,
                document: getFileId(),
                documentScreenshot: "",
                summary: "",
            });
        }
        else if (saveType === SaveDashboardItemType.Text) {
            if (!item.selections) {
                item.selections = [];
            }

            item.selections.push({
                id: uuidv4(),
                document: getFileId(),
                page: page,
                key: documentKey,
                selectedText: highlightData?.selectedText || "",
                title: title,
                summary: "",
            });
        }
        else if (saveType === SaveDashboardItemType.ScreenCapture) {
            if (!item.screenshots) {
                item.screenshots = [];
            }

            const fileName = `${uuidv4()}.png`;
            const metadata = await getS3DashboardScreenshotMetadata(user, fileName, item.id);

            await s3Upload({
                path: metadata.key,
                file: base64ToFile(screenCapture!, fileName)
            });

            item.screenshots.push({
                id: metadata.key,
                key: documentKey,
                title: title,
                summary: "",
                page: page,
                url: url,
            });
        }
    }

    const getHighlightStyle = () => {
        if (highlightData) {
            let topPosition = `${highlightData.selectionRegion.top + highlightData.selectionRegion.height}%`;
            let leftPosition = `${highlightData.selectionRegion.left}%`;

            const pageLayerElement = document.querySelector(".active-tab .rpv-core__page-layer");

            if (!pageLayerElement) {
                return {
                    left: leftPosition,
                    top: topPosition
                };
            }

            const leftPositionInPixels = pageLayerElement.clientWidth * highlightData.selectionRegion.left / 100;
            const topPositionInPixels = pageLayerElement.clientHeight * highlightData.selectionRegion.top / 100;

            if (leftPositionInPixels + 360 > pageLayerElement.clientWidth) {
                leftPosition = `${pageLayerElement.clientWidth - 370}px`;
            }

            if (topPositionInPixels + 285 > pageLayerElement.clientHeight) {
                topPosition = `${topPositionInPixels - 285}px`;
            }

            return {
                left: leftPosition,
                top: topPosition
            };
        }

        if (positionTop) {
            return {
                top: positionTop
            }
        }
    }

    const showErrorNotification = (dashboardName: string, isNew: boolean) => {
        let message: string = "";
        if (isNew) {
            message = 'Your session has expired. Please refresh the page and try again.';
        } else {
            switch (saveType) {
                case SaveDashboardItemType.Page:
                    message = `An error has occured while adding the page to dashboard ${dashboardName}.`;
                    break;

                case SaveDashboardItemType.ScreenCapture:
                    message = 'Your session has expired. Please refresh the page and try again.';
                    break;

                case SaveDashboardItemType.Text:
                    message = `An error has occured while adding the text selection to dashboard ${dashboardName}.`;
                    break;
                default:
                    break;
            }
        }

        enqueueSnackbar(message, {
            variant: "warning",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right"
            }
        });
    }

    return (
        <Paper className={classes.container} elevation={3} style={getHighlightStyle()}>
            {
                loading && <Box
                    sx={{
                        color: '#fff',
                        zIndex: 10000,
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        backgroundColor: '#000',
                        top: 0,
                        left: 0,
                        opacity: 0.2,
                        borderRadius: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <CircularProgress color="inherit" />
                </Box>
            }
            {
                isSuccess ?
                    <Grid container flexDirection="column" height={315} justifyContent="space-around" alignItems="center" py={6}>
                        <Grid item>
                            <Typography variant="h5">Item successfully added.</Typography>
                        </Grid>
                        <Grid item>
                            <CheckCircleOutlineIcon sx={{ color: green[500], fontSize: 100 }} />
                        </Grid>
                    </Grid>
                    :
                    <Grid container flexDirection="column">
                        <Grid item className={classes.detailsContainer}>
                            <Typography variant="body1" align="center">Selected item:</Typography>
                            {
                                saveType === SaveDashboardItemType.Text &&
                                <Typography variant="body1" align="center" color="primary" noWrap>{`"${highlightData?.selectedText}"`}</Typography>
                            }
                            <Grid container justifyContent="center">
                                {
                                    !isTitleEdit ?
                                        <>
                                            <Grid item xs={10.5} alignSelf="center">
                                                <Typography variant="body1" align="center">{title}</Typography>
                                            </Grid>
                                            <Grid item xs={1.5}>
                                                <IconButton onClick={() => { setIsTitleEdit(true); }}>
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item>
                                                <TextField
                                                    variant="standard"
                                                    autoFocus
                                                    type="text"
                                                    value={title}
                                                    sx={{ width: 230 }}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <IconButton onClick={() => { setIsTitleEdit(false); }}>
                                                    <CheckIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton onClick={() => {
                                                    setIsTitleEdit(false);
                                                    setTitle(title);
                                                }}>
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            </Grid>
                                        </>
                                }
                            </Grid>
                        </Grid>
                        <Grid item className={classes.addContainer} mb={1}>
                            <Typography variant="body1" mb={1}>Add to:</Typography>
                            <Grid container>
                                <Grid item xs={9} py={1}>
                                    {
                                        isAddNew ?
                                            <TextField
                                                variant="standard"
                                                placeholder="Enter dashboard name"
                                                autoFocus
                                                style={{ width: "100%" }}
                                                value={newDashboardName}
                                                disabled={loadingAddTo || loadingCreateNew}
                                                onChange={(e) => setNewDashboardName(e.target.value)}
                                            />
                                            :
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                sx={{ borderRadius: 54 }}
                                                className={classes.addNewButton}
                                                disabled={loadingAddTo || loadingCreateNew}
                                                startIcon={<AddIcon />}
                                                onClick={() => { setIsAddNew(true); }}
                                            >
                                                Add new dashboard
                                            </Button>
                                    }
                                </Grid>
                                <Grid item xs={9} py={1}>
                                    <Autocomplete
                                        options={dashboards}
                                        getOptionLabel={(option: Dashboard) => option.title.toString()}
                                        value={selectedDashboard}
                                        disabled={loadingAddTo || loadingCreateNew}
                                        onChange={(_, newValue) => {
                                            setSelectedDashboard(newValue || null);
                                        }}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.id}>
                                                    {option.title}
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                                placeholder="Select dashboard"
                                                data-id="add-to-dashboard-select"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} pt={2} display="flex" justifyContent="center">
                                    <LoadingButton
                                        variant="outlined"
                                        color="secondary"
                                        sx={{ borderRadius: 54, marginRight: 2 }}
                                        className={classes.addButton}
                                        startIcon={<AddIcon />}
                                        loading={loadingAddTo || loadingCreateNew}
                                        onClick={add}
                                    >
                                        Add
                                    </LoadingButton>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        sx={{ borderRadius: 54 }}
                                        className={classes.addButton}
                                        startIcon={<CancelIcon />}
                                        onClick={cancel}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            }
        </Paper >
    )
};

export default AddToDashboardPane;
