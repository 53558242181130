import { Box, Stack } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import moment from 'moment';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { DashboardQueryAnswer } from '../../../contexts/DashboardQueriesContext';
import useContentCopy from '../../../hooks/useContentCopy';
import { scrollbarStyle } from '../../../shared/dashboard';
import { AnswerQuestionResponse } from '../../../types/search';
import SkeletonLoader from '../../atoms/SkeletonLoader';
import AnswerEditor from '../dashboard-query-answer/AnswerEditor';
import AnswerHeader from '../dashboard-query-answer/AnswerHeader';

export type InlineQueryAnswerGroup = {
    queryAnswer: DashboardQueryAnswer;
    render: ReactNode;
    onUpdate: (answer: AnswerQuestionResponse) => void;
    onDeleteContext: (queryAnswer: DashboardQueryAnswer) => void;
};

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        paddingTop: 16,
        paddingBottom: 16,
        borderRadius: 16,
        border: `2px solid ${theme.colors.primary['100']}`,
        background: theme.colors.primary['50'],
        transition: 'all 0.5s ease',
    },
    contentOnly: {
        padding: 'unset',
        border: 'unset',
        background: 'transparent',
    },
    answerContainer: {
        width: 'auto',
        height: 'auto',
        marginLeft: 8,
        marginRight: 8,
        paddingLeft: 8,
        paddingRight: 8,
        border: `1px transparent solid`,
        borderRadius: 12,
    },
    answerHover: {
        '&:hover': {
            border: `1px ${theme.colors.neutral['400']} solid`,
            borderRadius: 12,
        }
    },
    answerBlock: {
        width: '100%',
        height: '48px',
        paddingTop: 8,
        paddingBottom: 8,
        color: '#666666',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
}));

const MaxWaitLoadingTime = 15;

const TeamQueryContainer: React.FC<{
    queryAnswer: DashboardQueryAnswer,
    children: ReactNode,
    displayMenu?: boolean,
    titleOnly?: boolean,
    onEdit: (answer: AnswerQuestionResponse) => void
    onDelete?: () => void,
}> = ({ queryAnswer, children, displayMenu, titleOnly, onEdit, onDelete }) => {
    const classes = useStyles();
    const { getCopiableId } = useContentCopy();

    const [isEditingAnswer, setIsEditingAnswer] = useState<boolean>(false);
    const [editAnswer, setEditAnswer] = useState<string | null>(null);
    const [hoverAnswer, setHoverAnswer] = useState<boolean>(false);
    const [showAnswer, setShowAnswer] = useState<boolean>(false);

    const { answeredQuestion } = queryAnswer;
    const copiableId = getCopiableId(answeredQuestion?.question);

    const saveEditAnswer = useCallback((answer: string) => {
        onEdit?.({answer: answer, sources: answeredQuestion.sources, type: answeredQuestion.type});
        setIsEditingAnswer(false);
        setEditAnswer(null);
    }, [answeredQuestion.sources, answeredQuestion.type, onEdit]);

    useEffect(() => {
        if ('answer' in answeredQuestion)
            setShowAnswer((!!answeredQuestion?.answer || !!answeredQuestion?.type)
                || (!!answeredQuestion?.time && moment().diff(answeredQuestion.time, "seconds") > MaxWaitLoadingTime));
        else
            setShowAnswer(true);
    }, [answeredQuestion]);

    return (<>
        <Box className={classnames(classes.masonryElement, classes.contentOnly)}
            onMouseEnter={() => setHoverAnswer(displayMenu || false)}
            onMouseLeave={() => setHoverAnswer(false)}>
            <AnswerHeader
                answeredQuestion={answeredQuestion}
                displayMenu={displayMenu}
                onSetIsEditingAnswer={() => setIsEditingAnswer(true)}
                onSetEditAnswer={(answer: any) => setEditAnswer(answer as string)}
                onDelete={onDelete}
                hideCopy
                hideAiGenerated
                showEditIcon={!isEditingAnswer ? hoverAnswer : false} />
            {isEditingAnswer ? (
                <Box px={2}>
                <AnswerEditor
                    currentAnswer={editAnswer || ''}
                    placeHolder={'Add answer...'}
                    rows={3}
                    handleCancel={() => {
                        setIsEditingAnswer(false);
                        setEditAnswer(null);
                        setHoverAnswer(false);
                    }}
                    handleSave={saveEditAnswer} />
                </Box>
            ) : (!titleOnly && (
                <Stack direction="row" className={classes.answerContainer} justifyContent="space-between">
                    <Box id={copiableId} className={classes.answerBlock} style={{ height: 'auto' }}>
                        {showAnswer ? ( children ) : (<SkeletonLoader variant="regular" rowCount={4} cropLast />)}
                    </Box>
                </Stack>
            ))}
        </Box>
    </>);
}

export default TeamQueryContainer;
