import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { scrollbarStyle } from "../../../shared/dashboard";
import { Dashboard, Workspace } from "../../../types/files";
import { FallbackLoading } from "../../templates/loader";
import NoDashboardsFoundView from "../dashboards-empty/NoDashboardsFoundView";
import DashboardsTableHeader from "../dashboards-table/DashboardsTableHeader";
import DashboardsTableRow from "../dashboards-table/DashboardsTableRow";
import { collectionKey } from "../../organisms/dashboards/DashboardsView";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    scrollable: {
        width: '100%',
        height: '100%',
        marginBottom: '16px !important',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    table: {
        width: '100%',
        height: 'calc(100vh - 240px)',
        overflow: 'hidden',
        marginLeft: 12,
    },
    headers: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        ...scrollbarStyle,
    },
    rows: {
        minWidth: '100%',
        width: 'fit-content',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
}));

const TableHeaders = ['Name', 'Stage', 'Sectors', 'Location', '', 'Added', ''];

const SharedByDealOwner: React.FC<{
    workspace?: Workspace,
    dashboards: Dashboard[],
    loading?: boolean,
}> = ({ workspace, dashboards, loading }) => {
    const classes = useStyles();

    if (!workspace)
        return (<></>);

    return (<>
        <Stack className={classes.container} alignItems="space-between" justifyContent="stretch">
            {loading ? (<FallbackLoading />) : (
                <Stack className={classes.table}>
                    {!!dashboards.length ? (<>
                        <Stack className={classes.headers} alignItems="flex-start" justifyContent="flex-start">
                            <DashboardsTableHeader
                                columns={TableHeaders}
                                actions={['Delete']}
                                xAdjust={80}
                                multiAction />
                            <Stack className={classes.rows} alignItems="flex-start" justifyContent="flex-start">
                                {dashboards.map(dashboard => (
                                    <DashboardsTableRow
                                        dashboard={dashboard}
                                        workspace={workspace ?? undefined}
                                        columns={TableHeaders}
                                        collectionKey={collectionKey}
                                        isPublic
                                        key={'shared-by-deal-owner-111-' + dashboard.id} />
                                ))}
                            </Stack>
                        </Stack>
                    </>) : (<NoDashboardsFoundView noMatch workspace={workspace} fullWidth />)}
                </Stack>
            )}
        </Stack>
    </>);
}

export default SharedByDealOwner;