import { Box, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import useRoute from "../../../hooks/useRoute";
import { updateWorkspaceFunc } from "../../../lib/helper";
import { defaultGeographies, defaultStages } from "../../../shared/dashboard";
import theme from "../../../theme";
import { Dashboard, Workspace } from "../../../types/files";
import DashboardAddToWorkspaceButton from "../../atoms/dashboards/DashboardAddToWorkspaceButton";
import MultiSelector from "../../atoms/MultiSelector";
import ThesisScore from "../../atoms/ThesisScore";
import WorkspaceSelect from "../../atoms/workspaces/WorkspaceSelect";
import WorkspaceShareEmail from "../../atoms/workspaces/WorkspaceShareEmail";
import WorkspaceMenu from "../workspace-menus/WorkspaceMenu";

const useStyles = makeStyles((theme) => ({
    row: {
        minWidth: '100%',
        width: 'fit-content',
        height: 'fit-content',
        borderBottom: `1px solid ${theme.colors.primary['300']}`,
        background: `#fff`,
        "&:hover": {
            background: `rgb(246, 251, 251, 0.8)`,
        },
    },
    solo: {
        padding: 8,
        background: `rgb(246, 251, 251, 0.8)`,
        borderRadius: 16,
        border: `1px solid ${theme.colors.cyan['500']}`,
    },
    cell: {
        minWidth: 200,
        maxWidth: 200,
        minHeight: 60,
        maxHeight: 60,
        padding: '2px 10px',
    },
    wideCell: {
        minWidth: 250,
        maxWidth: 250,
    },
    fullWidthCell: {
        minWidth: 0,
        maxWidth: 'unset !important',
        width: '100%',
    },
    endCell: {
        minWidth: 4,
        maxWidth: 4,
        padding: 'unset',
    },
    label: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        textDecoration: 'none',
    },
    soloTitle: {
        fontSize: '1.1em !important',
        fontWeight: 'bold !important',
        color: theme.palette.common.black,
    },
    sublabel: {
        fontWeight: '500',
        color: theme.colors.neutral['500'],
    },
    value: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['500'],
    },
    icon: {
        width: 20,
        height: 20,
    },
    checkBox: {
        padding: 'unset !important',
        marginRight: '4px !important',
        "& > svg": {
            width: '20px !important',
            height: '20px !important',
        }
    },
}));

const WorkspacesTableRow: React.FC<{
    workspace: Workspace,
    dashboard?: Dashboard,
    collectionKey?: string,
    forSharing?: boolean,
    noSharing?: boolean,
    noMailing?: boolean,
    solo?: boolean,
}> = ({ workspace, dashboard, collectionKey, forSharing, noSharing, noMailing, solo }) => {
    const classes = useStyles();
    const { setWorkspaces } = useContext(GroupSettingsContext);
    const { mappedOverviewQueries } = useContext(DashboardsContext);
    const { redirectToWorkspace } = useRoute();
    const [updating, setUpdating] = useState<string>('');

    const tags = useMemo(() => workspace?.investmentThesis?.focusArea?.tags || [], [workspace]);
    const stages = useMemo(() => workspace?.investmentThesis?.stage?.stages || [], [workspace]);
    const regions = useMemo(() => workspace?.investmentThesis?.geography?.regions || [], [workspace]);

    const getQueryAnswer = useCallback((title: string) => (
        mappedOverviewQueries.get(`${dashboard?.id}:${title}`)
    ), [dashboard, mappedOverviewQueries]);

    const handleSave = useCallback(async (selections: string[], type: string) => {
        const investmentThesis = { ...workspace!.investmentThesis };

        switch (type) {
            case 'focusArea':
                investmentThesis.focusArea = { tags: selections };
                break;
            case 'stage':
                investmentThesis.stage = { stages: selections };
                break;
            case 'geography':
                investmentThesis.geography = { regions: selections };
                break;
        }

        setUpdating(type);
        updateWorkspaceFunc({...workspace, investmentThesis }).then((updatedWorkspace?: Workspace) => {
            setWorkspaces(prev => prev.map(ws => ws.id === workspace.id ? updatedWorkspace! : ws));
            setUpdating('');
        });
    // eslint-disable-next-line
    }, [workspace]);

    return (<>
        <Stack direction="column"  className={classnames(classes.row, solo && classes.solo)}
            alignItems="stretch" justifyContent="flex-start">
            <Stack direction="row" alignItems="stretch" justifyContent="flex-start" width="100%">
                {!solo && (
                    <Stack direction="column" alignItems="center" justifyContent="center" minWidth={24} width="min-content">
                        {!!collectionKey ? (
                            <WorkspaceSelect collection={collectionKey} workspace={workspace} />
                        ) : (<Box width={24} />)}
                    </Stack>
                )}
                <Stack direction="column" className={classes.cell} spacing={1} alignItems="flex-start" justifyContent="center"
                    onClick={() => !forSharing ? redirectToWorkspace(workspace.id) : undefined}
                    sx={{ cursor: !forSharing ? 'pointer' : 'auto' }}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                        <Typography className={classnames(classes.label, solo && classes.soloTitle)}>
                            {workspace.name}
                        </Typography>
                        {forSharing && (<ThesisScore workspaceId={workspace.id} investmentTheses={getQueryAnswer('Investment Thesis') || []} />)}
                    </Stack>
                </Stack>
                <Stack direction="column" className={classes.cell} alignItems="flex-start" justifyContent="center">
                    <Typography className={classnames(classes.label, classes.sublabel)} >
                        {workspace.email}
                    </Typography>
                </Stack>
                <Stack direction="column" className={classnames(classes.cell, classes.wideCell)}
                    alignItems="flex-start" justifyContent="center">
                    <MultiSelector
                        mainOptions={tags}
                        selections={tags}
                        onSelection={(selections) => handleSave(selections, 'focusArea')}
                        readOnly={false}
                        disabled={false}
                        loading={updating === 'focusArea'}
                        cropped={230} />
                </Stack>
                <Stack direction="column" className={classnames(classes.cell, classes.wideCell)}
                    alignItems="flex-start" justifyContent="center">
                    <MultiSelector
                        mainOptions={defaultStages}
                        selections={stages}
                        onSelection={(selections) => handleSave(selections, 'stage')}
                        background="#E0F1F1"
                        foreGround="#048290"
                        readOnly={false}
                        disabled={false}
                        loading={updating === 'stage'}
                        cropped={230} />
                </Stack>
                <Stack direction="column" className={classnames(classes.cell, classes.wideCell)}
                    alignItems="flex-start" justifyContent="center">
                    <MultiSelector
                        mainOptions={defaultGeographies}
                        selections={regions}
                        onSelection={(selections) => handleSave(selections, 'geography')}
                        background={theme.colors.blue['100']}
                        foreGround={theme.colors.blue['900']}
                        readOnly={false}
                        disabled={false}
                        loading={updating === 'geography'}
                        cropped={230} />
                </Stack>
                <Stack direction="row" className={classnames(classes.cell, classes.fullWidthCell)}
                    spacing={1} alignItems="center" justifyContent="flex-end">
                    {!forSharing ? (<>
                        {!noSharing && (<WorkspaceShareEmail workspace={workspace} />)}
                        <WorkspaceMenu workspace={workspace} />
                    </>) : (!!dashboard) && (<>
                        <DashboardAddToWorkspaceButton dashboard={dashboard} workspaceId={workspace.id} />
                        {!noMailing && (<WorkspaceShareEmail dashboard={dashboard} workspace={workspace} />)}
                    </>)}
                </Stack>
                <Stack className={classnames(classes.cell, classes.endCell)} />
            </Stack>
        </Stack>
    </>);
}

export default memo(WorkspacesTableRow);